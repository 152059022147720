export const Download = () => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
            stroke="#FF6A83"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
