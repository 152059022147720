import { useEffect, useMemo, useRef } from 'react';
import { useWindowSize } from 'usehooks-ts';

import AppHeader from '@components/common/headers/app-header/AppHeader';
import AppNav from '@components/common/navs/app-nav/AppNav';
import { KeyboardArrowRightMedium } from '@components/svg/arrows/KeyboardArrowRightMedium';
import { getIsTablet } from '@constants/media';
import withClearCache from '@hoc/withClearCache';
import { useAppSelector, useClearStoreOnURLChange } from '@hooks/redux/useRedux';
import useAnimateSidebar from '@hooks/sidebar/useAnimateSidebar';
import useSidebar from '@hooks/sidebar/useSidebar';
import AppRoutes from '@routes/AppRoutes';

import Show from '@components/common/condition/Show';
import OutlineClose from '@components/svg/OutlineClose';
import AppOnlySocialRoutes from '@routes/AppOnlySocialRoutes';
import { selectedFromUser } from '@store/authentication/authentication.reducer';
import './DashboardLayout.sass';

declare interface LayoutProps {
    HeaderComponent?: React.ReactNode;
}

function DashboardLayout({ HeaderComponent }: LayoutProps) {
    const size           = useWindowSize();
    const switchToMobile = useMemo(() => getIsTablet(), [size.width, size.height]);
    const sidebar        = useSidebar();
    const { onlySocialMedia } = useAppSelector(selectedFromUser.settings);
    const container                         = useRef<HTMLDivElement>();
    const appWrapperRef = useRef(null);
    useClearStoreOnURLChange();
    useAnimateSidebar(container);

    const onToggleSideBar = () => {
        if (switchToMobile){
            sidebar.toggleStatus(false, 'mobile');
            return;
        }

        /**
         * if true => expanded
         * if false => collapsed
         */
        sidebar.toggleStatus(!sidebar.state.sideNavStatus, 'desktop');
    }

    const sidebarClass   = !sidebar.state.sideNavStatus ? 'pinned' : '';
    const isDesktopClass = switchToMobile ? '' : '--desktop';
    useEffect(()=>{
        window.appWrapperRef = appWrapperRef;
    },[appWrapperRef.current]);

    return (
        <div className="app-split" ref={container}>
            <section className={`app-nav ${sidebarClass} ${isDesktopClass}`} style={switchToMobile ? { opacity: 0 }: {}}>
                <div className="inner-nav">
                    <AppNav sidebarStatus={!switchToMobile ? sidebar.state.sideNavStatus : sidebar.state.mobileSideNavStatus} />

                    <Show>
                        <Show.When isTrue={switchToMobile}>
                            <OutlineClose className='nav-close' onClick={onToggleSideBar} />
                        </Show.When>
                        <Show.Else>
                            <div
                                className="nav-collapse"
                                onClick={onToggleSideBar}
                            >
                                <KeyboardArrowRightMedium style={{ rotate: '180deg' }} />
                            </div>
                        </Show.Else>
                    </Show>
                </div>
            </section>
            <section ref={appWrapperRef} className={`app-wrapper ${sidebarClass}`}>
                <AppHeader 
                    disabledSearch={onlySocialMedia} 
                    sidebarStatus={!switchToMobile ? sidebar.state.sideNavStatus : sidebar.state.mobileSideNavStatus}
                />

                {HeaderComponent && HeaderComponent}

                {onlySocialMedia ? <AppOnlySocialRoutes /> : <AppRoutes />}
            </section>
        </div>
    );
}


export default withClearCache(DashboardLayout);
