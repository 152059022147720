import Config from 'config/config';
import { SocialMediaTypes } from 'shared/enum/socialMediaTypes';

/**
 * Builds a url query from a specified args
 *
 * @param {String} endpoint API endpoint
 * @param {Object} args object of urlParamKey:urlParamValue pairs
 * @returns {String} API constructed url
 */
export function queryBuilder(endpoint: string, args?: { [key: string]: any }): string {
    if (!args) {
        return endpoint;
    }

    const entries    = Object.entries(args);
    let url          = `${endpoint}?`;
    let isFirstParam = true;

    for (const [urlParamKey, urlParamValue] of entries){
        if (urlParamValue == null) {
            continue;
        }

        if (isFirstParam) {
            url          += `${urlParamKey}=${String(urlParamValue)}`;
            isFirstParam = false;
        } else {
            url += `&${urlParamKey}=${String(urlParamValue)}`;
        }
    }

    return url;
}


export const isUrl = (url: string): boolean => url.includes('https://') || url.includes('http://');

/**
 *
 * Gets a webpage using XMLHttp.
 *
 * @param {String} url - page url to get.
 */
/* istanbul ignore next */
export const getPageUsingXMLHttpRequest = async (url: string) => {
    let xmlhttp;

    if ('XMLHttpRequest' in window) {xmlhttp = new XMLHttpRequest();}

    if ('ActiveXObject' in window) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        xmlhttp = new ActiveXObject('Msxml2.XMLHTTP');
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    xmlhttp.open('GET', url, true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    xmlhttp.onreadystatechange = function () {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (xmlhttp.readyState === 4) {
            sessionStorage.setItem(
                'scraped-page',
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                JSON.stringify(xmlhttp.responseText),
            );
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    xmlhttp.send(null);
};

const { api: { loginUrl } }  = Config;

export const getLoginUrl = (saveCurrentPath = true) => {
    const url = queryBuilder(loginUrl, saveCurrentPath ? {
        sp: encodeURIComponent(window.location.href)
    } : null);

    return url;
};

export const validateSocialMediaPageUrl = (pageUrl: string, socialType: SocialMediaTypes): {
    isValid: boolean;
    message: string;
} => {
    const message = 'Invalid media url! Url should be like';

    switch (socialType) {
        case SocialMediaTypes.FACEBOOK:
            return {
                isValid: pageUrl.includes('facebook.'),
                message: `${message} https://www.facebook.com/Indepth`
            };
        case SocialMediaTypes.INSTAGRAM:
            return {
                isValid: pageUrl.includes('instagram.'),
                message: message + ' https://www.instagram.com/Indepth/'
            };
        case SocialMediaTypes.TWITTER:
            return {
                isValid: pageUrl.includes('x.'),
                message: message + ' https://x.com/Indepth'
            };
        case SocialMediaTypes.YOUTUBE:
            return {
                isValid: pageUrl.includes('youtube.'),
                message: message + ' https://www.youtube.com/@Indepth',
            };
        case SocialMediaTypes.TIKTOK:
            return {
                isValid: pageUrl.includes('tiktok.'),
                message: message + ' https://www.tiktok.com/@Indepth',
            };

        default:
            return { isValid: false, message: 'Unknown media type!' };
    }
};
