import classNames from 'classnames';
import { Fragment, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import { Logout } from '@components/svg/Logout';
import { Cube, Indepth } from '@components/svg/indepth';
import { Audience, Content, FacebookInMenu, GenderTracker, HeatMap, InstagramInMenu, Mentions, Overview, Realtime, Report, Settings, SocialMedia, TiktokInMenu, TwitterInMenu, YoutubeInMenu } from '@components/svg/pages';
import { getIsTablet } from '@constants/media';
import { useGetUserSelectedSite } from '@hooks/index';
import { useAppDispatch, useAppSelector } from '@hooks/redux/useRedux';
import { getIsClientAllowedGenderTracker } from '@pages/gender-tracker/GenderTrackerRoutePrivilege';
import useFetchSocialMediaOverviewData from '@pages/social-media/useFetchOverviewData';
import { DefinedRoutes, OnlySocialMediaRoutes } from '@routes/definedRoutes';
import { getSocialMediaSingleUrl } from '@routes/definedRoutesUtils';
import { isInAudiencePage, isInContentPage, isInGenderTrackerPage } from '@services/validation/validation';
import { logoutUser } from '@store/authentication/authentication.actions';
import { selectedFromUser } from '@store/authentication/authentication.reducer';
import { selectedFromSocialMediaOverview } from '@store/social-media/overview/socialMediaOverview.slice';

import { AppNavGroup, type AppNavItemProps } from './';
import './AppNav.sass';

export declare interface NavProps {
    sidebarStatus: boolean;
    logoutUser?: any;
}

const AppNav = ({ sidebarStatus }: NavProps) => {
    const dispatch                        = useAppDispatch();
    const size                            = useWindowSize();
    const location                        = useLocation();
    const { pathname }                    = location;
    const { hasAuthors, onlySocialMedia } = useAppSelector(selectedFromUser.settings);

    const switchToMobile = useMemo(() => getIsTablet(), [size.width, size.height]);

    const navClass: string = !sidebarStatus && !switchToMobile ? 'collapsed' : '';
    const isCollapsed      = !sidebarStatus && !switchToMobile;

    const selectedSite           = useGetUserSelectedSite();
    const isGenderTrackerAllowed = getIsClientAllowedGenderTracker(selectedSite?.name);

    const { data: highlights } = useAppSelector(selectedFromSocialMediaOverview.highlights);

    useFetchSocialMediaOverviewData({
        shouldFetch: !highlights.length
    });

    const dropdowns: Record<string, {
        empty: boolean;
        path: string | null;
        hasItems: boolean;
        items: {
            title: string;
            isLink: boolean;
            path: string;
        }[];
    }> = {
        facebook: {
            empty: true,
            path: null,
            hasItems: false,
            items: []
        },
        instagram: {
            empty: true,
            path: null,
            hasItems: false,
            items: []
        },
        twitter: {
            empty: true,
            path: null,
            hasItems: false,
            items: []
        },
        youtube: {
            empty: true,
            path: null,
            hasItems: false,
            items: []
        },
        tiktok: {
            empty: true,
            path: null,
            hasItems: false,
            items: []
        },
    };

    for (const highlight of highlights) {
        const path = getSocialMediaSingleUrl(
            highlight.networkType,
            highlight.profileId
        );

        if (dropdowns?.[highlight.networkType].path == null) {
            dropdowns[highlight.networkType].path  = path;
            dropdowns[highlight.networkType].empty = false;
        }


        dropdowns[highlight.networkType].items.push({
            title: highlight.profileName,
            isLink: true,
            path,
        });

        if (dropdowns[highlight.networkType].items.length > 1) {
            dropdowns[highlight.networkType].hasItems = true;
        }
    }

    const socialMediaNavGroup = [
        {
            groupName: 'SOCIAL MEDIA',
            groupItems: [
                {
                    icon: <Overview />,
                    name: 'Overview',
                    route: OnlySocialMediaRoutes.overview.path
                },
                !dropdowns.facebook.empty && {
                    icon: <FacebookInMenu />,
                    name: 'Facebook',
                    ...(!dropdowns.facebook.hasItems ? {
                        route: dropdowns.facebook.path
                    }: {
                        onClick: () => null,
                        isItemActive: OnlySocialMediaRoutes.facebook.validator(pathname),
                        validator: () => OnlySocialMediaRoutes.facebook.validator(pathname),
                        dropdownItems: dropdowns.facebook.items
                    }),
                },
                !dropdowns.instagram.empty && {
                    icon: <InstagramInMenu />,
                    name: 'Instagram',
                    ...(!dropdowns.instagram.hasItems ? {
                        route: dropdowns.instagram.path
                    }: {
                        onClick: () => null,
                        isItemActive: OnlySocialMediaRoutes.instagram.validator(pathname),
                        validator: () => OnlySocialMediaRoutes.instagram.validator(pathname),
                        dropdownItems: dropdowns.instagram.items
                    }),
                },
                !dropdowns.twitter.empty && {
                    icon: <TwitterInMenu />,
                    name: 'X',
                    ...(!dropdowns.twitter.hasItems ? {
                        route: dropdowns.twitter.path
                    }: {
                        onClick: () => null,
                        isItemActive: OnlySocialMediaRoutes.twitter.validator(pathname),
                        validator: () => OnlySocialMediaRoutes.twitter.validator(pathname),
                        dropdownItems: dropdowns.twitter.items
                    }),
                },
                !dropdowns.youtube.empty && {
                    icon: <YoutubeInMenu />,
                    name: 'Youtube',
                    ...(!dropdowns.youtube.hasItems ? {
                        route: dropdowns.youtube.path
                    }: {
                        onClick: () => null,
                        isItemActive: OnlySocialMediaRoutes.youtube.validator(pathname),
                        validator: () => OnlySocialMediaRoutes.youtube.validator(pathname),
                        dropdownItems: dropdowns.youtube.items
                    }),
                },
                !dropdowns.tiktok.empty && {
                    icon: <TiktokInMenu />,
                    name: 'Tiktok',
                    ...(!dropdowns.tiktok.hasItems ? {
                        route: dropdowns.tiktok.path
                    }: {
                        onClick: () => null,
                        isItemActive: OnlySocialMediaRoutes.tiktok.validator(pathname),
                        validator: () => OnlySocialMediaRoutes.tiktok.validator(pathname),
                        dropdownItems: dropdowns.tiktok.items
                    }),
                }
            ].filter(Boolean)
        },
        {
            groupName: 'ADMIN',
            groupItems: [
                {
                    icon: <Settings />,
                    name: 'Settings',
                    route: DefinedRoutes.settings.path
                },
                {
                    icon: <Logout />,
                    name: 'Logout',
                    onClick:  () => {
                        dispatch(logoutUser());
                    }
                },
            ]
        }
    ];
    const defaultNavGroup     = [
        {
            groupName: 'CONTENT',
            groupItems: [
                {
                    icon: <Overview />,
                    name: 'Overview',
                    route: DefinedRoutes.overview.path
                },
                {
                    icon: <Realtime />,
                    name: 'Real time',
                    route: DefinedRoutes.realTime.path
                },
                {
                    icon: <Audience />,
                    name: 'Audience',
                    onClick: () => null,
                    isItemActive: isInAudiencePage(pathname),
                    validator: () => isInAudiencePage(pathname),
                    dropdownItems: [
                        {
                            title: 'Overview',
                            isLink: true,
                            path: DefinedRoutes.audience.overview.path,
                        },
                        {
                            title: 'Countries',
                            isLink: true,
                            path: DefinedRoutes.audience.countries.path,
                        },
                        {
                            title: 'Devices',
                            isLink: true,
                            path: DefinedRoutes.audience.devices.path,
                        },
                        {
                            title: 'Browsers',
                            isLink: true,
                            path: DefinedRoutes.audience.browsers.path,
                        },
                        {
                            title: 'Acquisition',
                            isLink: true,
                            path: DefinedRoutes.audience.acquisition.path,
                        },
                    ]
                },
                {
                    icon: <Content />,
                    name: 'Content',
                    onClick: () => null,
                    isItemActive: isInContentPage(pathname),
                    validator: () => isInContentPage(pathname),
                    dropdownItems: [
                        {
                            title: 'Overview',
                            isLink: true,
                            path: DefinedRoutes.pages.overview.path,
                        },
                        {
                            title: 'Articles and pages',
                            isLink: true,
                            path: DefinedRoutes.pages.articlesAndPages.path,
                        },
                        {
                            title: 'Categories',
                            isLink: true,
                            path: DefinedRoutes.pages.categories.path,
                        },
                        hasAuthors ? {
                            title: 'Authors',
                            isLink: true,
                            path: DefinedRoutes.pages.authors.path,
                        } : null,
                    ].filter(Boolean)
                },
                {
                    icon: <Report />,
                    name: 'Report',
                    isDisabled: true,
                    onClick: () => null
                },
                isGenderTrackerAllowed ? {
                    icon: <GenderTracker />,
                    name: 'Gender tracker',
                    onClick: () => null,
                    isItemActive: isInGenderTrackerPage(pathname),
                    validator: () => isInGenderTrackerPage(pathname),
                    dropdownItems: [
                        {
                            title: 'Overview',
                            isLink: true,
                            path: DefinedRoutes.genderTracker.overview.path,
                        },
                        {
                            title: 'Reports',
                            isLink: true,
                            path: DefinedRoutes.genderTracker.reports.path,
                        },
                        {
                            title: 'Track content',
                            isLink: true,
                            path: DefinedRoutes.genderTracker.trackContent.path,
                        },
                    ],
                } : null,
                {
                    icon: <HeatMap />,
                    name: 'Heatmaps',
                    route: DefinedRoutes.heatmap.path
                },
            ].filter(Boolean)
        },
        {
            groupName: 'PRODUCTS',
            groupItems: [
                {
                    icon: <SocialMedia />,
                    name: 'Social Media',
                    route: DefinedRoutes.socialMedia.path
                },
                {
                    icon: <Mentions fill='#E3E3E1'/>,
                    name: 'Mentions',
                    route: DefinedRoutes.mentions.path
                },
            ]
        },
        {
            groupName: 'ADMIN',
            groupItems: [
                {
                    icon: <Settings />,
                    name: 'Settings',
                    route: DefinedRoutes.settings.path
                },
                {
                    icon: <Logout />,
                    name: 'Logout',
                    onClick:  () => {
                        dispatch(logoutUser());
                    }
                },
            ]
        }
    ];

    const navGroups: {
        groupName: string;
        groupItems: (Omit<AppNavItemProps, 'isCollapsed'> & { validator?: () => boolean })[];
    }[] = onlySocialMedia ? socialMediaNavGroup : defaultNavGroup;

    return (
        <nav data-testid="nav-container" className={`nav-wrapper ${navClass}`}>
            <div className="nav-indepth-logo-wrapper">
                <NavLink to={DefinedRoutes.overview.path}>
                    <Cube className={classNames('indepth-cube-logo')} />
                    <Indepth className={classNames('indepth-logo')} />
                </NavLink>
            </div>

            <div className="app-nav-groups">
                {navGroups.map((groupProps, idx) => (
                    <Fragment key={`${groupProps.groupName}-${idx}`}>
                        <AppNavGroup
                            isCollapsed={isCollapsed}
                            {...groupProps}
                        />
                        {idx !== navGroups.length-1 && <div className="app-nav-groups-separator" />}
                    </Fragment>
                ))}
            </div>
        </nav>
    );
};

export default AppNav;
