import { addDays, differenceInDays, formatISO, parseISO } from 'date-fns';
// import arTN from 'date-fns/locale/ar-TN';
import enUS from 'date-fns/locale/en-US';
import { useMemo, useState } from 'react';
import { DateRangePicker, RangeFocus } from 'react-date-range';

import { isInSocialMediaPage } from '@routes/definedRoutesUtils';
import { selectFromMentions } from '@store/mentions/mentions.slice';
import { ThemeColors } from 'constants/colors';
import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import useUpdateEffect from 'hooks/update-effect/useUpdateEffect';
import { useLocation } from 'react-router-dom';
import { getSelectedSiteStartDay } from 'services/client-config';
import DateInstance from 'services/date/date';
import { DatepickerShortcuts } from 'shared/enum/datepickerShortcuts';
import { setActiveShortcut, setRangeSelection } from 'store/date-picker/datePicker.actions';
import useDatePickerSecondMonthTitle from './useDatePickerSecondMonthTitle';

let nextAppended     = false;
const clientStartDay = getSelectedSiteStartDay();

function DatePickerRangePicker() {
    const { selectionState, compareStatus, mentionStartDate } = useAppSelector(state => {
        const mentionStartDate = selectFromMentions.mentions(state)?.data?.oldest_date;

        return {
            selectionState: state.datePickerReducer.rangePickerSelection,
            compareStatus: state.datePickerReducer.compareStatus,
            mentionStartDate: mentionStartDate || null
        };
    });
    const dispatch                                            = useAppDispatch();
    const { pathname }                                        = useLocation();
    const [focusedRanges, setFocusedRanges]                   = useState<RangeFocus>([0, 0]);

    // reset focused selection when compareStatus's set to false
    useUpdateEffect(() => {
        !compareStatus && setFocusedRanges([0, 0]);
    }, [compareStatus]);

    const updateTitle                   = useDatePickerSecondMonthTitle({ nextAppended });

    const ranges                            = useMemo(() => {
        if (!compareStatus){
            return [selectionState.selection1];
        }

        return [selectionState.selection1, selectionState.selection2];
    }, [compareStatus, selectionState]);


    const minDate = useMemo(() => {
        try {
            // if in social media page, don't use min date
            const isInSocial = isInSocialMediaPage(pathname);

            if (isInSocial) {
                return undefined;
            }


            if (DateInstance.isValidDate(String(mentionStartDate), 'yyyy-MM-dd')) {
                const parsedDate    = parseISO(mentionStartDate);
                const formattedDate = formatISO(parsedDate, { representation: 'date' });

                return DateInstance.newDate(formattedDate);
            }

            if (!clientStartDay){
                return undefined;
                // return DateInstance.newDate();
            }


            const parsedDate = clientStartDay ? parseISO(clientStartDay) : null;
            const format     = parsedDate ? formatISO(parsedDate, { representation: 'date' }) : null;

            return DateInstance.newDate(format);
        } catch (e) {
            console.log('error:', e);
        }
    }, [clientStartDay, mentionStartDate, pathname]);


    return (
        <div className="dp-picker">
            <DateRangePicker
                ranges={ranges}
                rangeColors={['#93969D', ThemeColors.themeHighlightColor]}

                focusedRange={focusedRanges}
                onRangeFocusChange={(nextRanges) => setFocusedRanges([0, nextRanges[1]])}

                onChange={(item) => {
                    nextAppended = false;
                    dispatch(setActiveShortcut(DatepickerShortcuts.None));

                    const selection      = { ...selectionState, ...item };
                    const { selection1 } = selection;

                    const selectionDifference = differenceInDays(selection1.endDate, selection1.startDate);
                    const compareEndDate      = addDays(selection1.startDate, -1);
                    const compareStartDate    = addDays(compareEndDate, -selectionDifference);

                    const newSelection: typeof selection = {
                        ...selection,
                        selection2: {
                            ...selection.selection2,
                            startDate: compareStartDate,
                            endDate: compareEndDate,
                        }
                    };

                    dispatch(setRangeSelection(newSelection));

                    updateTitle();
                }}

                minDate={minDate}
                maxDate={addDays(DateInstance.newDate(), -1)}

                months={2}

                direction="horizontal"
                locale={enUS}
                weekStartsOn={0}

                showMonthArrow
                dragSelectionEnabled
                showMonthAndYearPickers
                preventSnapRefocus={false}
                editableDateInputs={false}
                moveRangeOnFirstSelection={false}
                retainEndDateOnFirstSelection={false}
            />
        </div>
    );
}

export default DatePickerRangePicker;
