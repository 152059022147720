import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';

const persistConfig    = {
    key: 'root',
    storage,

    // prevent persisting for the following reducer names
    // blacklist: [],

    // add persisting for the following reducer names
    whitelist: ['authenticationReducer', 'socialMediaOverviewReducer'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store     = configureStore({
    reducer:    persistedReducer,
    devTools:   process.env.NODE_ENV !== 'production',
    // middleware: [thunk],
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});
const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const getStoreState = (): RootState => store.getState();

export { getStoreState, persistor, store };

