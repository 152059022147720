import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DefaultPageSkeletonBuilder from '@components/skeletons/default-page-skeleton/DefaultPageSkeletonBuilder';
import PageSuspense from '@components/skeletons/page-suspense/PageSuspense';
import SocialMediaSinglesLayout from '@layouts/social-media/SocialMediaSinglesLayout';
import NotFound from '@pages/not-found/NotFound';

import AppRoutesBoundary from './boundary/AppRoutesBoundary';
import { OnlySocialMediaRoutes } from './definedRoutes';
import PublicOutlet from './validator/PublicOutlet';
import withAuth from './with-auth/withAuth';

const SocialMedia        = lazy(() => import('@pages/social-media/SocialMedia'));
const Settings           = lazy(() => import('pages/settings/Settings'));

const Facebook        = lazy(() => import('@pages/social-media/singles/facebook/Facebook'));
const FacebookAllPosts = lazy(() => import('@pages/social-media/singles/facebook/all-posts/FacebookAllPosts'));

const Instagram        = lazy(() => import('@pages/social-media/singles/instagram/Instagram'));
const InstagramAllPosts = lazy(() => import('@pages/social-media/singles/instagram/all-posts/InstagramAllPosts'));
const InstagramAllStories = lazy(() => import('@pages/social-media/singles/instagram/all-stories/InstagramAllStories'));

const Twitter        = lazy(() => import('@pages/social-media/singles/twitter/Twitter'));
const TwitterAllPosts = lazy(() => import('@pages/social-media/singles/twitter/all-posts/TwitterAllPosts'));

const Youtube        = lazy(() => import('@pages/social-media/singles/youtube/Youtube'));
const YoutubeAllPosts = lazy(() => import('@pages/social-media/singles/youtube/all-posts/YoutubeAllPosts'));

const Tiktok         = lazy(() => import('@pages/social-media/singles/tiktok/Tiktok'));
const TiktokAllPosts = lazy(() => import('@pages/social-media/singles/tiktok/all-posts/TiktokAllPosts'));

const AppOnlySocialRoutes = (routeProps) => (
    <AppRoutesBoundary>
        {/* ↓ this top level suspense is here in case a page has no fallback loader */}
        <Suspense fallback={<PageSuspense SkeletonBuilder={DefaultPageSkeletonBuilder}/>}>
            <Routes>
                <Route path="/" element={<Navigate to={OnlySocialMediaRoutes.overview.path} />}/>

                <Route path= "" element={<PublicOutlet />}>
                    {/* overview page */}
                    <Route index path={OnlySocialMediaRoutes.overview.name} element={<SocialMedia {...routeProps} />} />


                    {/* mentions */}
                    {/* <Route path={OnlySocialMediaRoutes.mentions.path} element={<Mentions {...routeProps} />} /> */}

                    {/* social media */}
                    {/* <Route path={OnlySocialMediaRoutes.overview.name}> */}
                        <Route index path="" element={<SocialMedia {...routeProps} />} />
                        <Route element={<SocialMediaSinglesLayout />}>
                            <Route path={OnlySocialMediaRoutes.facebook.name}>
                                <Route index path="" element={<Facebook {...routeProps} />} />
                                <Route 
                                    path={OnlySocialMediaRoutes.facebook.posts.name} 
                                    element={<FacebookAllPosts {...routeProps} />} 
                                />
                            </Route>
                            <Route path={OnlySocialMediaRoutes.instagram.name}>
                                <Route index path="" element={<Instagram {...routeProps} />} />
                                <Route 
                                    path={OnlySocialMediaRoutes.instagram.posts.name} 
                                    element={<InstagramAllPosts {...routeProps} />} 
                                />
                                <Route 
                                    path={OnlySocialMediaRoutes.instagram.stories.name} 
                                    element={<InstagramAllStories {...routeProps} />} 
                                />
                            </Route>

                            <Route path={OnlySocialMediaRoutes.twitter.name}>
                                <Route index path="" element={<Twitter {...routeProps} />} />
                                <Route 
                                    path={OnlySocialMediaRoutes.twitter.posts.name} 
                                    element={<TwitterAllPosts {...routeProps} />} 
                                />
                            </Route>

                            <Route path={OnlySocialMediaRoutes.youtube.name}>
                                <Route index path="" element={<Youtube {...routeProps} />} />
                                <Route 
                                    path={OnlySocialMediaRoutes.youtube.posts.name} 
                                    element={<YoutubeAllPosts {...routeProps} />} 
                                />
                            </Route>

                            <Route path={OnlySocialMediaRoutes.tiktok.name}>
                                <Route index path="" element={<Tiktok {...routeProps} />} />
                                <Route
                                    path={OnlySocialMediaRoutes.tiktok.posts.name}
                                    element={<TiktokAllPosts {...routeProps} />}
                                />
                            </Route>
                        </Route>
                    </Route>

                    {/* settings */}
                    <Route path={OnlySocialMediaRoutes.settings.path} element={<Settings {...routeProps} />} />
                {/* </Route> */}

                {/* @ts-ignore */}
                <Route path="*" element={<NotFound />}/>
            </Routes>
        </Suspense>
    </AppRoutesBoundary>
);

export default withAuth(AppOnlySocialRoutes);