import { useState } from 'react';

import DateInstance from '@services/date/date';

import { Table } from '@components/common';
import { Download } from '@components/svg';
import { ThemeColors } from '@constants/colors';
import './profileBillingHistory.scss';

const fakeData = new Array(20).fill(null)
    .map(
        (_, idx) => ({
            id: Math.floor(Math.random() * 999999),
            status: idx !== 0 ? 'Paid' : 'Unpaid',
            amount: Math.floor(Math.random() * 999),
            date: DateInstance.newDate(),
        })
    );

const OrderToSortingMapper = {
    Invoices: 'invoices',
    Status: 'status',
    Amount: 'amount',
    Date: 'date',
};

const ProfileBillingHistory = () => {
    const [sort, setSort] = useState(null);
    const bodyItems       = fakeData.map((item) => (
        [
            {
                title: item.id,
                titleBold: true,
            },
            item.status,
            String(item.amount),
            <div>
                <p>{DateInstance.formatDate({
                    date: DateInstance.newDate(item.date),
                    format: 'dd MMM yyyy, hh:mm'
                })}</p>

                <div style={{
                    cursor: 'pointer'
                }}>
                    {item.status.toLowerCase() === 'paid' ? <Download /> : (
                        <p style={{
                            fontSize: '.8rem',
                            textDecoration: 'underline',
                            color: ThemeColors.themeStatsColor2,
                        }}>Pay Now</p>
                    )}
                </div>
            </div>
        ]
    ));

    return (
        <div className='profile-invoices'>
            <div>
                <h2>Invoices</h2>
                <p>See history of your payment plan.</p>
            </div>

            <div className="pi-table">
                <Table
                    onOrderChange={(orderKey: string, isOrderReversed: boolean) => {
                        setSort({ with: OrderToSortingMapper?.[orderKey], reversed: isOrderReversed });
                    }}
                    defaultOrder={3}

                    disabledDownloader
                    itemsNotBold
                    goToLinkWhenClickedOnTdFirstChild
                    headerItems={Object.keys(OrderToSortingMapper)}
                    bodyItems={bodyItems}

                    emptyBodyItemsMessage={'No Results'}
                />
            </div>
        </div>
    );
};


export default ProfileBillingHistory;
