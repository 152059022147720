import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks/redux/useRedux';
import useErrorReporter from '@hooks/useErrorReporter';
import useGetUserSelectedSite from '@hooks/user/useGetUserSelectedSite';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import { selectFromDatepicker } from '@store/date-picker/datePicker.reducer';
import { selectedFromSocialMediaOverview } from '@store/social-media/overview/socialMediaOverview.slice';
import { fetchSocialMediaOverview } from '@store/social-media/overview/socialMediaOverview.thunk';

export default function useFetchSocialMediaOverviewData({ shouldFetch = true }: { shouldFetch?: boolean }) {
    const { dates, pastDates, data, error, status } = useAppSelector(state => ({
        ...selectFromDatepicker.dates(state),
        ...selectedFromSocialMediaOverview.highlights(state)
    }));
    const dispatch                                  = useAppDispatch();
    const userSite                                  = useGetUserSelectedSite();

    useErrorReporter({ error, status });

    useEffect(() => {
        if (!shouldFetch || !userSite) {
            return;
        }

        const [from, to]               = dates;
        const [compareFrom, compareTo] = pastDates;

        const source = generateNewCancelTokenSource();

        dispatch(fetchSocialMediaOverview({
            data: {
                start_date: String(from),
                end_date: String(to),
                compareFrom: String(compareFrom),
                compareTo: String(compareTo),
                client_id: userSite.clientId,
            },
            config: {
                cancelToken: source.token
            }
        }));

        return () => {
            source && source.cancel();
        };
    }, [dates, pastDates, shouldFetch]);

    return { data, error, status };
}
