import { SettingsTabsIDs } from '@pages/settings/Settings';

export const DefinedRoutes = {
    overview: {
        name: 'overview',
        path: '/overview',
    },
    heatmap: {
        name: 'heatmap',
        path: '/heatmap',
    },
    realTime: {
        name: 'realtime',
        path: '/realtime',
    },
    audience: {
        name: 'audience',
        path: '/audience',

        overview: {
            name: 'overview',
            path: '/audience/overview',
        },
        countries: {
            name: 'countries',
            path: '/audience/countries',
        },
        country: { // single country
            name: 'country',
            path: '/audience/country',
        },
        devices: {
            name: 'devices',
            path: '/audience/devices',
        },
        browsers: {
            name: 'browsers',
            path: '/audience/browsers',
        },
        acquisition: {
            name: 'acquisition',
            path: '/audience/acquisition',
        },
    },
    pages: {
        name: 'content',
        path: '/content',

        overview: {
            name: 'overview',
            path: '/content/overview',
        },
        articlesAndPages: {
            name: 'articles-and-pages',
            path: '/content/articles-and-pages',
        },
        categories: {
            name: 'categories',
            path: '/content/categories',
        },
        authors: {
            name: 'authors',
            path: '/content/authors',
        },

        // single pages
        page: {
            name: 'page',
            path: '/content/page',
        },
        category: {
            name: 'category/:category',
            path: '/content/category/:category',
            slug: ':category'
        },
        author: {
            name: 'author/:author',
            path: '/content/author/:author',
            slug: ':author'
        },
    },
    genderTracker: {
        name: 'gender-tracker',
        path: '/gender-tracker',

        overview: {
            name: 'overview',
            path: '/gender-tracker/overview',
        },
        reports: {
            name: 'reports',
            path: '/gender-tracker/reports',
        },
        trackContent: {
            name: 'track-content',
            path: '/gender-tracker/track-content',
        },

        singleReport: {
            name: 'report/:id',
            path: '/gender-tracker/report/:id',
            slug: [':id']
        },
    },
    mentions: {
        name: 'mentions',
        path: '/mentions',
    },
    socialMedia: {
        name: 'social-media',
        path: '/social-media',
        validator: (path: string) => new RegExp('[/social-media|/social-media/]$').test(path),

        // social media single
        details: {
            name: 'details/:type/:profileId',
            path: '/social-media/details/:type/:profileId',
            slug: [':type', ':profileId']
        },
        singles: {
            validator: (path: string) => path.includes('/social-media/'),
            facebook: {
                validator: (path: string) => path.includes('/social-media/facebook/'),
                name: 'facebook/:profileId',
                path: '/social-media/facebook/:profileId',
                slug: [':profileId'],

                posts: {
                    validator: (path: string) => path.includes('/social-media/facebook') && path.includes('/all-posts'),
                    name: 'all-posts',
                    path: '/social-media/facebook/:profileId/all-posts',
                    getPath: (profileId: string) => `/social-media/facebook/${profileId}/all-posts`,
                    slug: [':profileId'],
                }
            },
            instagram: {
                validator: (path: string) => path.includes('/social-media/instagram/'),
                name: 'instagram/:profileId',
                path: '/social-media/instagram/:profileId',
                slug: [':profileId'],

                posts: {
                    validator: (path: string) => path.includes('/social-media/instagram') && path.includes('/all-posts'),
                    name: 'all-posts',
                    path: '/social-media/instagram/:profileId/all-posts',
                    getPath: (profileId: string) => `/social-media/instagram/${profileId}/all-posts`,
                    slug: [':profileId'],
                },
                stories: {
                    validator: (path: string) => path.includes('/social-media/instagram') && path.includes('/all-stories'),
                    name: 'all-stories',
                    path: '/social-media/instagram/:profileId/all-stories',
                    getPath: (profileId: string) => `/social-media/instagram/${profileId}/all-stories`,
                    slug: [':profileId'],
                }
            },
            twitter: {
                validator: (path: string) => path.includes('/social-media/x/'),
                name: 'x/:profileId',
                path: '/social-media/x/:profileId',
                slug: [':profileId'],

                posts: {
                    validator: (path: string) => path.includes('/social-media/x') && path.includes('/all-posts'),
                    name: 'all-posts',
                    path: '/social-media/x/:profileId/all-posts',
                    getPath: (profileId: string) => `/social-media/x/${profileId}/all-posts`,
                    slug: [':profileId'],
                },
            },
            youtube: {
                validator: (path: string) => path.includes('/social-media/youtube/'),
                name: 'youtube/:profileId',
                path: '/social-media/youtube/:profileId',
                slug: [':profileId'],

                posts: {
                    validator: (path: string) => path.includes('/social-media/youtube') && path.includes('/all-posts'),
                    name: 'all-posts',
                    path: '/social-media/youtube/:profileId/all-posts',
                    getPath: (profileId: string) => `/social-media/youtube/${profileId}/all-posts`,
                    slug: [':profileId'],
                },
            },
            tiktok: {
                validator: (path: string) => path.includes('/social-media/tiktok/'),
                name: 'tiktok/:profileId',
                path: '/social-media/tiktok/:profileId',
                getPath: (profileId: string) => `/social-media/tiktok/${profileId}/all-posts`,
                slug: [':profileId'],

                posts: {
                    validator: (path: string) => path.includes('/social-media/tiktok') && path.includes('/all-posts'),
                    name: 'all-posts',
                    path: '/social-media/tiktok/:profileId/all-posts',
                    getPath: (profileId: string) => `/social-media/tiktok/${profileId}/all-posts`,
                    slug: [':profileId'],
                },
            },
        },
    },
    settings: {
        name: 'settings',
        path: '/settings',

        tabs: {
            profile: {
                name: SettingsTabsIDs.profile,
                path: `/settings?tab=${SettingsTabsIDs.profile}`
            },
            socials: {
                name: SettingsTabsIDs.socials,
                path: `/settings?tab=${SettingsTabsIDs.socials}`
            },
        }
    },
} as const;

export const OnlySocialMediaRoutes = {
    overview: {
        name: 'overview',
        path: '/overview',
        validator: (path: string) => path.includes('/overview/') && !new RegExp('[/overview|/overview/]$').test(path),
    },
    settings: {
        name: 'settings',
        path: '/settings',

        tabs: {
            profile: {
                name: SettingsTabsIDs.profile,
                path: `/settings?tab=${SettingsTabsIDs.profile}`
            },
            socials: {
                name: SettingsTabsIDs.socials,
                path: `/settings?tab=${SettingsTabsIDs.socials}`
            },
        }
    },
    facebook: {
        // validator: (path: string) => path.includes('/overview/facebook') && !new RegExp('[/overview/facebook|/overview/facebook/]$').test(path),
        validator: (path: string) => path.includes('/facebook'),
        name: 'facebook/:profileId',
        path: '/facebook/:profileId',
        slug: [':profileId'],

        posts: {
            validator: (path: string) => path.includes('/facebook') && path.includes('/all-posts'),
            name: 'all-posts',
            path: '/facebook/:profileId/all-posts',
            getPath: (profileId: string) => `/facebook/${profileId}/all-posts`,
            slug: [':profileId'],
        }
    },
    instagram: {
        validator: (path: string) => path.includes('/instagram/'),
        name: 'instagram/:profileId',
        path: '/instagram/:profileId',
        slug: [':profileId'],

        posts: {
            validator: (path: string) => path.includes('/instagram') && path.includes('/all-posts'),
            name: 'all-posts',
            path: '/instagram/:profileId/all-posts',
            getPath: (profileId: string) => `/instagram/${profileId}/all-posts`,
            slug: [':profileId'],
        },
        stories: {
            validator: (path: string) => path.includes('/instagram') && path.includes('/all-stories'),
            name: 'all-stories',
            path: '/instagram/:profileId/all-stories',
            getPath: (profileId: string) => `/instagram/${profileId}/all-stories`,
            slug: [':profileId'],
        }
    },
    twitter: {
        validator: (path: string) => path.includes('/x/'),
        name: 'x/:profileId',
        path: '/x/:profileId',
        slug: [':profileId'],

        posts: {
            validator: (path: string) => path.includes('/x') && path.includes('/all-posts'),
            name: 'all-posts',
            path: '/x/:profileId/all-posts',
            getPath: (profileId: string) => `/x/${profileId}/all-posts`,
            slug: [':profileId'],
        },
    },
    youtube: {
        validator: (path: string) => path.includes('/youtube/'),
        name: 'youtube/:profileId',
        path: '/youtube/:profileId',
        slug: [':profileId'],

        posts: {
            validator: (path: string) => path.includes('/youtube') && path.includes('/all-posts'),
            name: 'all-posts',
            path: '/youtube/:profileId/all-posts',
            getPath: (profileId: string) => `/youtube/${profileId}/all-posts`,
            slug: [':profileId'],
        },
    },
    tiktok: {
        validator: (path: string) => path.includes('/tiktok/'),
        name: 'tiktok/:profileId',
        path: '/tiktok/:profileId',
        slug: [':profileId'],

        posts: {
            validator: (path: string) => path.includes('/tiktok') && path.includes('/all-posts'),
            name: 'all-posts',
            path: '/tiktok/:profileId/all-posts',
            getPath: (profileId: string) => `/tiktok/${profileId}/all-posts`,
            slug: [':profileId'],
        },
    },
};
