import { ReactNode, useState } from 'react';
import Select from 'react-select';

import Show from '@components/common/condition/Show';
import { SocialMediaLogos } from '@constants/socialMedia';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { type SelectOption } from '@shared/interfaces/component/selectOptions';
import { CustomSelectStyles } from 'constants/reactSelect';

import './SocialMediaSettingsAddForm.sass';

export interface SocialMediaSettingsAddFormProps {
    mediaId: number;
    isSelectDisabled?: boolean;
    defaultUrl?: string;
    defaultSelectedOption?: SelectOption;
    selectOptions?: SelectOption<SocialMediaTypes | string>[];
    onChangeSelect?: (mediaId: number, option: SelectOption) => void;
    onChangeUrl?: (mediaId: number, url: string) => void;
    onDelete?: (mediaId: number) => void;
    shouldConfirmDelete?: boolean;
    isLoading?: boolean;
}

export const SocialMediaSettingsOptionLabel = ({ image, svg, title }: { image?: string; svg?: ReactNode; title: string }) => (
    <div className="smsaf-label">
        {!svg && image && <img src={image} alt="label thumbnail" />}
        {svg && svg}
        <p>{title}</p>
    </div>
);

const defaultOption: SelectOption<string>                    = {
    label: <SocialMediaSettingsOptionLabel title="Select option" />,
    value:'*',
    placeholder: 'Select an option first'
};
const defaultSelectOptions: SelectOption<SocialMediaTypes>[] = [
    { label: <SocialMediaSettingsOptionLabel image={SocialMediaLogos.facebook.normal} title="Facebook" />, value: SocialMediaTypes.FACEBOOK },
    { label: <SocialMediaSettingsOptionLabel image={SocialMediaLogos.instagram.normal} title="Instagram" />, value: SocialMediaTypes.INSTAGRAM },
    { label: <SocialMediaSettingsOptionLabel image={SocialMediaLogos.twitter.normal} title="Twitter" />, value: SocialMediaTypes.TWITTER },
    { label: <SocialMediaSettingsOptionLabel image={SocialMediaLogos.youtube.normal} title="Youtube" />, value: SocialMediaTypes.YOUTUBE },
    { label: <SocialMediaSettingsOptionLabel image={SocialMediaLogos.tiktok.normal} title="Tiktok" />, value: SocialMediaTypes.TIKTOK },
];

const placeholderMapper = {
    [SocialMediaTypes.INSTAGRAM]: 'https://www.instagram.com/your-page',
    [SocialMediaTypes.FACEBOOK]: 'https://www.facebook.com/your-page',
    [SocialMediaTypes.TWITTER]: 'https://www.x.com/your-page',
    [SocialMediaTypes.YOUTUBE]: 'https://www.youtube.com/channel/your-channel-id',
    [SocialMediaTypes.TIKTOK]: 'https://www.tiktok.com/username',
};

const SocialMediaSettingsAddForm = ({
    mediaId,
    isSelectDisabled = false,
    defaultUrl = '',
    defaultSelectedOption = defaultOption,
    onChangeSelect,
    onChangeUrl,
    onDelete,
    selectOptions = defaultSelectOptions,
}: SocialMediaSettingsAddFormProps) => {
    const [selectedOption, setSelectedOption] = useState<SelectOption>(null);
    const [url, setUrl]                       = useState(defaultUrl);

    const handleChangeSelect = (option: SelectOption) => {
        setSelectedOption(option);
        onChangeSelect?.(mediaId, option);
    };

    const handleChangeUrl = (e) => {
        const { value } = e.target;

        setUrl(value);
        onChangeUrl?.(mediaId, url);
    };

    const selectedMedia    = selectedOption?.value ?? defaultOption?.placeholder;
    const inputPlaceholder = selectedOption
        ? placeholderMapper[selectedMedia]
        : selectedMedia;

    return (
        <div className='sms-add-form'>
            <Select
                options={selectOptions}
                defaultValue={selectedOption ?? defaultSelectedOption}
                onChange={handleChangeSelect}

                isSearchable={false}
                isDisabled={isSelectDisabled}
                // menuIsOpen

                styles={{
                    menu: (provided) => ({
                        ...CustomSelectStyles.menu(provided),
                        fontSize: '0.7rem',
                        fontFamily: 'Poppins, Cairo, Roboto, sans-serif',
                    })
                }}
                className="smsaf-select"
                classNamePrefix="smsaf"
            />

            <Show.When isTrue={selectedOption != null}>
                <input
                    type="url"
                    value={url ?? ''}
                    disabled={isSelectDisabled}
                    onChange={handleChangeUrl}
                    onKeyUp={handleChangeUrl}
                    placeholder={inputPlaceholder}
                />
            </Show.When>

            <Show.When isTrue={selectedOption != null && url !== ''}>
                <button type="button" onClick={() => onDelete?.(mediaId)}>
                    Delete
                </button>
            </Show.When>
        </div>
    );
};

export default SocialMediaSettingsAddForm;
