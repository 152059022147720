import { useState } from 'react';
import { useNavigate } from 'react-router';

import { Each, PrimaryButton, Show, SocialMediaAddButton } from '@components/common';
import { Plus } from '@components/svg';
import { ErrorMessage, LoaderWrapper, useToast } from '@components/widgets';
import { SocialMediaLogos } from '@constants/socialMedia';
import { getSocialMediaSingleUrl } from '@routes/definedRoutesUtils';
import { validateSocialMediaPageUrl } from '@services/url/url';
import { isArrayEmpty } from '@services/validation/validation';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { SelectOption } from '@shared/interfaces/component/selectOptions';

import {
    SocialMediaConfirmDelete, SocialMediaProfileActive,
    SocialMediaProfileInProgress,
    SocialMediaSettingsAddForm,
    SocialMediaSubmitFeedback,
    SocialMediaSubmitFeedbackCopyLink,
    useSocialMediaProfiles,
} from './';
import './SocialMediaSettings.scss';

export interface AddedMedia {
    id: string;
    mediaId: number;
    url?: string;
    name: string;
    thumbnail: string;
    network: SocialMediaTypes;
    selectedOption?: SelectOption;

    defaultUrl?: string;
    defaultSelectedOption?: SelectOption;
    isSelectDisabled?: boolean;
    shouldConfirmDelete?: boolean;
    isActive: boolean;
    isInForm: boolean;
}

const SocialMediaSettings = () => {
    const navigate                                              = useNavigate();
    const [addedMedia, setAddedMedia]                           = useState<AddedMedia[]>([]);
    const [formError, setFormError]                             = useState('');
    const [shouldShowConfirmDelete, setShouldShowConfirmDelete] = useState({ url: null, show: false });
    const [shouldShowSubmitConfirm, setShouldShowSubmitConfirm] = useState(false);

    const {
        isLoading,
        refetchProfiles,
        onAddProfile,
        resetProfileAdd,
        isAddProfileLoading,
        onDeleteProfile,
        resetProfileDelete,
        isDeleteProfileLoading,
    }                      = useSocialMediaProfiles({
        setAddedMedia,
        onAddProfileSuccess: () => {
            setShouldShowSubmitConfirm(true);
            // setAddedMedia((prev) => {
            //     const lastMedia = prev.shift();

            //     return [{ ...lastMedia, isSelectDisabled: true, shouldConfirmDelete: true }, ...prev];
            // });
            resetProfileAdd();
            refetchProfiles();
        },
        onAddProfileFailed: () => {
            setFormError('Failed to add profile!');
        },
        onDeleteProfileSuccess: (deletedUrl) => {
            setAddedMedia(prev => prev.filter(
                item => decodeURIComponent(item.url) !== decodeURIComponent(deletedUrl)
            ));
            resetProfileDelete();
            setShouldShowConfirmDelete({ url: null, show: false });
        }
    });

    const { triggerToast } = useToast({ toastId: 'sms-form' });

    const onMediaChangeSelect = (mediaId: number, option: SelectOption) => {
        setAddedMedia((prev) => prev.map((mediaItem) => ({
            ...mediaItem,
            selectedOption: mediaItem.mediaId === mediaId ? option : mediaItem.selectedOption
        })));
    };

    const onMediaChangeUrl    = (mediaId: number, url: string) => {
        setAddedMedia((prev) => prev.map((mediaItem) => ({
            ...mediaItem,
            url: mediaItem.mediaId === mediaId ? url : mediaItem.url
        })));
    };

    const onMediaDelete       = (mediaId: number, withRequest = true) => {
        if (!withRequest) {
            setAddedMedia(prev => prev.filter(item => item.mediaId !== mediaId));
            setFormError('');

            return;
        }

        const media = addedMedia.find(media => media.mediaId === mediaId);

        if (!media || !media.id) {

            return;
        }

        onDeleteProfile(media.url);
        setFormError('');
    };

    const onAddMedia          = () => {
        const shouldAdd = validateLastAddedMedia(addedMedia);

        if (!shouldAdd.valid) {
            triggerToast('Fill in the media platform you added before adding more!', 'info');

            return;
        }

        const lastMediaID   = addedMedia?.at(-1)?.mediaId ?? 0;
        const firstMediaID  = addedMedia?.at(0)?.mediaId ?? 0;
        const latestMediaID = lastMediaID > firstMediaID ? lastMediaID : firstMediaID;

        setAddedMedia([
            {
                id: '',
                mediaId: latestMediaID + 1,
                name: '',
                thumbnail: '',
                url: '',
                network: null,
                shouldConfirmDelete: false,
                isActive: false,
                isInForm: true,
            },
            ...addedMedia,
        ]);
        setFormError('');
    };

    const onSubmit = () => {
        const isValid = validateLastAddedMedia(addedMedia);

        setFormError('');

        if (!isValid.valid) {
            const message = (() => {
                const { reason } = isValid;

                if (reason === 'url') {
                    setFormError(isValid.message);

                    return 'Invalid media url!';
                }

                setFormError('');

                return 'Please select a media platform!';
            })();

            triggerToast(message, 'warn');

            return;
        }

        const newMedia =  addedMedia.filter(media => media.isInForm).map((media) => ({
            url: media.url,
            network: media.selectedOption.value as unknown as SocialMediaTypes,
        }));

        onAddProfile(newMedia);
    };

    const activeMedia   = addedMedia.toReversed().filter(media => media.isActive && !media.isInForm) ?? [];
    const inactiveMedia = addedMedia.toReversed().filter(media => !media.isActive && !media.isInForm) ?? [];
    const informMedia   = addedMedia.toReversed().filter(media => !media.isActive && media.isInForm) ?? [];

    return (
        <>
            <section className='social-media-settings'>
                <h2>Social media</h2>
                <p>
                    Connect your favorite social media accounts,
                    add profile links, and unlock a personalized
                    analytics dashboard for a deeper dive into your
                    digital world. Let's get started!🚀
                </p>
                <div className='sms-separator' />

                <div className="sms-links">
                    {isLoading && <LoaderWrapper />}

                    <Show.When isTrue={isArrayEmpty(addedMedia) || (isArrayEmpty(activeMedia) && isArrayEmpty(inactiveMedia) && !isArrayEmpty(informMedia))}>
                        <h4>Profiles</h4>
                        <p>No social media accounts.</p>

                    </Show.When>

                    <Show.When isTrue={!isArrayEmpty(activeMedia)}>
                        <h4>Active profiles</h4>
                        <SocialMediaProfileActive
                            items={activeMedia.map((item) => ({
                                icon: SocialMediaLogos?.[item.network]?.filled,
                                url: item.url,
                                name: item.name,
                                thumbnail: item.thumbnail,
                                onDelete: () => {
                                    if (item?.shouldConfirmDelete === true) {
                                        setShouldShowConfirmDelete({ url: item.url, show: true });

                                        return;
                                    }

                                    onDeleteProfile(item.url);
                                },
                                onView: () => {
                                    const pathToDetails = getSocialMediaSingleUrl(item.network, item.id);

                                    navigate(pathToDetails);
                                }
                            }))}
                        />
                        <div className='sms-separator' />
                    </Show.When>

                    <Show>
                        <Show.When isTrue={!!formError}>
                            <ErrorMessage
                                messageStyle={{ fontSize: '.9rem' }}
                                wrapperStyle={{ marginBottom: 0 }}
                                message={formError}
                            />
                        </Show.When>
                        <Show.When isTrue={!isArrayEmpty(inactiveMedia)}>
                            <h4>Profiles in progress</h4>
                            <SocialMediaSubmitFeedbackCopyLink
                                paragraphStyle={{
                                    marginBottom: '.5rem',
                                    fontSize: 'min(16px, 1rem)',
                                    maxWidth: '50rem',
                                }}
                                style={{
                                    marginBottom: '1.5rem',
                                    background: 'transparent',
                                    padding: 0,
                                    maxWidth: '50rem',
                                }}
                            />
                            <SocialMediaProfileInProgress
                                items={inactiveMedia.map(item => ({
                                    icon: SocialMediaLogos?.[item.network]?.normal,
                                    url: item.url,
                                    onDelete: () => {
                                        if (item?.shouldConfirmDelete === true) {
                                            setShouldShowConfirmDelete({ url: encodeURIComponent(item.url), show: true });

                                            return;
                                        }

                                        onDeleteProfile(encodeURIComponent(item.url));
                                    }
                                }))}
                            />
                            <div className='sms-separator' style={{ marginTop: '2rem' }} />
                        </Show.When>
                    </Show>

                    <Show.When isTrue={!isArrayEmpty(informMedia)}>
                        <Each of={informMedia} render={(media) => (
                            <SocialMediaSettingsAddForm
                                // wrapperId={`sms-${media.id}-${idx}`}
                                onChangeSelect={onMediaChangeSelect}
                                onChangeUrl={onMediaChangeUrl}
                                onDelete={(mediaId) => onMediaDelete(mediaId, false)}
                                {...media}
                            />
                        )}/>
                    </Show.When>
                </div>

                <SocialMediaAddButton onClick={onAddMedia} className='sms-add-btn'>
                    <Plus />
                    <p>Add new profile</p>
                </SocialMediaAddButton>

                <Show.When isTrue={!isArrayEmpty(informMedia) && !!informMedia.find(media => !media.isSelectDisabled)}>
                    <PrimaryButton isLoading={isAddProfileLoading} onClick={onSubmit}>
                        Save profiles
                    </PrimaryButton>
                </Show.When>
            </section>

            <SocialMediaConfirmDelete
                shouldShow={shouldShowConfirmDelete.show}
                isLoading={isDeleteProfileLoading}
                onConfirm={() => {
                    onDeleteProfile(shouldShowConfirmDelete.url);
                }}
                onClose={() => setShouldShowConfirmDelete({ url: null, show: false })}
            />

            <SocialMediaSubmitFeedback
                shouldShow={shouldShowSubmitConfirm}
                onClose={() => setShouldShowSubmitConfirm(false)}
            />
        </>
    );
};

const validateLastAddedMedia = (addedMedia: AddedMedia[]) => {
    if (!addedMedia?.length) {
        return { valid: true };
    }

    const lastMedia             = addedMedia.at(0);
    const isSelectedOptionValid = lastMedia?.selectedOption && lastMedia.selectedOption.value !== '*';

    if (!isSelectedOptionValid) {
        return { valid: false, reason: 'select' };
    }

    const socialUrlValidation = validateSocialMediaPageUrl(
        lastMedia.url,
        lastMedia.selectedOption.value as SocialMediaTypes
    );
    const isUrlValid          = lastMedia?.url && socialUrlValidation.isValid;

    if (isUrlValid || lastMedia?.isSelectDisabled === true){
        return { valid: true };
    }

    return { valid: false, reason: 'url', message: socialUrlValidation.message };
};

export default SocialMediaSettings;
