export const TiktokInMenu = () => (
    <svg
        width={17}
        height={20}
        viewBox="0 0 17 20"
        fill="none"
    >
        <path
            d="M12.5976 6.93469C13.838 7.82095 15.3576 8.34241 16.9988 8.34241V5.18587C16.6882 5.18599 16.3784 5.15358 16.0745 5.08918V7.57383C14.4334 7.57383 12.914 7.05244 11.6733 6.16624V12.6079C11.6733 15.8304 9.05961 18.4425 5.83572 18.4425C4.63281 18.4425 3.5147 18.079 2.58594 17.4556C3.646 18.539 5.12432 19.211 6.75977 19.211C9.98392 19.211 12.5977 16.5989 12.5977 13.3763V6.93469H12.5976ZM13.7378 3.75012C13.1039 3.05791 12.6876 2.16335 12.5976 1.17435V0.768372H11.7217C11.9421 2.02534 12.6942 3.09922 13.7378 3.75012ZM4.62504 14.9828C4.27082 14.5187 4.07937 13.9509 4.08026 13.367C4.08026 11.8932 5.27574 10.6982 6.75067 10.6982C7.0255 10.6981 7.29871 10.7403 7.5607 10.8233V7.59615C7.25454 7.55424 6.94559 7.53638 6.63678 7.54295V10.0548C6.37466 9.97173 6.10132 9.92957 5.82636 9.92978C4.35149 9.92978 3.15608 11.1247 3.15608 12.5987C3.15608 13.6409 3.75359 14.5433 4.62504 14.9828Z"
            fill="#FF004F"
        />
        <path
            d="M11.673 6.16617C12.9137 7.05237 14.433 7.57377 16.0742 7.57377V5.08911C15.1581 4.89406 14.3472 4.41563 13.7375 3.75012C12.6938 3.09915 11.9418 2.02528 11.7213 0.768372H9.42063V13.3761C9.41539 14.846 8.22197 16.0361 6.75022 16.0361C5.88302 16.0361 5.11251 15.6229 4.62459 14.9828C3.75327 14.5433 3.1557 13.6409 3.1557 12.5987C3.1557 11.1249 4.3511 9.92985 5.82597 9.92985C6.10855 9.92985 6.38091 9.97381 6.63639 10.0548V7.54302C3.46909 7.60843 0.921875 10.195 0.921875 13.3762C0.921875 14.9642 1.55617 16.4039 2.58568 17.4557C3.51445 18.079 4.63249 18.4426 5.83547 18.4426C9.05942 18.4426 11.673 15.8303 11.673 12.6079L11.673 6.16617Z"
            fill="black"
        />
        <path
            d="M16.0766 5.08912V4.41743C15.2505 4.41863 14.4407 4.18741 13.7398 3.7502C14.3602 4.42906 15.1772 4.89721 16.0766 5.08925M11.7236 0.768382C11.7026 0.648282 11.6865 0.52738 11.6753 0.405974V0H8.49856V12.6079C8.49351 14.0776 7.3001 15.2677 5.82828 15.2677C5.41104 15.2683 4.99949 15.1708 4.6269 14.983C5.11482 15.623 5.88533 16.0361 6.75253 16.0361C8.22421 16.0361 9.41776 14.8461 9.42294 13.3763V0.768448L11.7236 0.768382ZM6.6389 7.54303V6.82784C6.37345 6.7916 6.10583 6.77345 5.83791 6.77352C2.61363 6.77352 0 9.38575 0 12.6079C0 14.6281 1.02719 16.4084 2.58813 17.4557C1.55861 16.4039 0.924317 14.9642 0.924317 13.3762C0.924317 10.1951 3.47147 7.60844 6.6389 7.54303Z"
            fill="#00F2EA"
        />
    </svg>
);
