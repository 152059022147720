import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { store } from '@store/storeConfig';
import { DefinedRoutes, OnlySocialMediaRoutes } from './definedRoutes';

export const getSocialMediaDetailsUrl = (type: SocialMediaTypes, id: string) => {
    const url = DefinedRoutes.socialMedia.details.path
        .replace(
            DefinedRoutes.socialMedia.details.slug[0],
            type
        ).replace(
            DefinedRoutes.socialMedia.details.slug[1],
            id
        );

    return url;
};

export const isInSocialMediaPage = (pathname: string) => {
    const onlySocialMedia = store.getState()?.authenticationReducer?.onlySocialMedia || false;

    if (onlySocialMedia) {
        return (
            OnlySocialMediaRoutes.overview.validator(pathname) ||
            OnlySocialMediaRoutes.facebook.validator(pathname) ||
            OnlySocialMediaRoutes.instagram.validator(pathname) ||
            OnlySocialMediaRoutes.twitter.validator(pathname) ||
            OnlySocialMediaRoutes.youtube.validator(pathname) ||
            OnlySocialMediaRoutes.tiktok.validator(pathname)
        );
    }

    return (
        DefinedRoutes.socialMedia.validator(pathname) ||
        DefinedRoutes.socialMedia.singles.facebook.validator(pathname) ||
        DefinedRoutes.socialMedia.singles.instagram.validator(pathname) ||
        DefinedRoutes.socialMedia.singles.twitter.validator(pathname) ||
        DefinedRoutes.socialMedia.singles.youtube.validator(pathname) ||
        DefinedRoutes.socialMedia.singles.tiktok.validator(pathname)
    );
};

export const getSocialMediaSingleUrl = (type: SocialMediaTypes, id: string): string => {
    if (!type) {
        throw new Error('type is mandatory');
    }

    const onlySocialMedia = store.getState()?.authenticationReducer?.onlySocialMedia || false;

    const route = onlySocialMedia
        ? OnlySocialMediaRoutes[type]
        : DefinedRoutes.socialMedia.singles[type];

    const url   = route.path.replace(
        route.slug[0],
        id
    );

    return url;
};

export const getGenderTrackerSingleReportUrl = (id: string, link = 'none') => {
    const url = DefinedRoutes.genderTracker.singleReport.path
        .replace(
            DefinedRoutes.genderTracker.singleReport.slug[0],
            id
        );

    return `${url}?url=${encodeURIComponent(link)}`;
};
