import { isEmpty } from 'lodash';

import type { DecodedToken } from 'services/auth-token/authToken';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';

import { RootState } from '@store/storeConfig';
import {
    REMOVE_CURRENT_USER,
    SET_CURRENT_USER,
    SET_CURRENT_USER_SETTINGS,
    SET_USER_LOADING_STATE,
    UPDATE_CURRENT_USER
} from './authentication.types';

export type AuthenticatedUser = Omit<DecodedToken, 'iat'> & {
    selectedSiteIndex: number;
};

export declare interface AuthenticationState{
    isAuthenticated: boolean;
    user: AuthenticatedUser;
    isLoading: boolean;

    hasAuthors: boolean;
    hasLanguages: boolean;
    onlySocialMedia: boolean;
}

const initState: AuthenticationState = {
    isAuthenticated: false,
    user: null,
    isLoading: true,
    hasAuthors: null,
    hasLanguages: null,
    onlySocialMedia: false,
};

const authenticationReducer = (state = initState, action: ReducerAction): AuthenticationState => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action?.payload?.username) && !isEmpty(action?.payload?.site),
                user: action?.payload,
            };
        case SET_CURRENT_USER_SETTINGS:
            return {
                ...state,
                hasAuthors: action.payload?.hasAuthors ?? false,
                hasLanguages: action.payload?.hasLanguages ?? false,
                onlySocialMedia: action.payload?.onlySocialMedia ?? false,
            };
        case UPDATE_CURRENT_USER:
            return {
                ...state,
                user: {
                    ...(state.user || {}),
                    ...action.payload
                }
            };
        case REMOVE_CURRENT_USER:
            return {
                isAuthenticated: false,
                user: null,
                isLoading: false,
                hasAuthors: null,
                hasLanguages: null,
                onlySocialMedia: false,
            };
        case SET_USER_LOADING_STATE:
            return {
                ...state,
                isLoading: action.payload
            };
        default:
            return state;
    }
};

export const selectedFromUser = {
    settings: (state: RootState) => ({
        hasAuthors: state.authenticationReducer.hasAuthors,
        hasLanguages: state.authenticationReducer.hasLanguages,
        onlySocialMedia: state.authenticationReducer.onlySocialMedia,
    }),
};

export default authenticationReducer;
