import './profilePlan.scss';

const ProfilePlan = () => {
    let s;

    return (
        <div className='profile-plan'>
            <div>
                <h2>Plan</h2>
                <p>Changes to these preferences will apply to future invoices only.</p>
            </div>
        </div>
    );
};

export default ProfilePlan;
