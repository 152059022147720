export const YoutubeInMenu = () => (
    <svg
        width={20}
        height={14}
        viewBox="0 0 20 14"
        fill="none"
    >
        <path
            d="M19.5586 2.1934C19.4439 1.76945 19.2201 1.38295 18.9096 1.07238C18.599 0.761819 18.2125 0.538026 17.7886 0.423288C16.2365 0 9.99003 0 9.99003 0C9.99003 0 3.7432 0.0128127 2.19115 0.436101C1.76719 0.550845 1.3807 0.77465 1.07015 1.08523C0.759594 1.39581 0.535823 1.78232 0.421116 2.20629C-0.0483452 4.96399 -0.230458 9.16609 0.434007 11.8135C0.548725 12.2374 0.772502 12.6239 1.08305 12.9345C1.3936 13.2451 1.78009 13.4689 2.20404 13.5836C3.75609 14.0069 10.0028 14.0069 10.0028 14.0069C10.0028 14.0069 16.2494 14.0069 17.8013 13.5836C18.2253 13.4689 18.6118 13.2451 18.9224 12.9345C19.2329 12.6239 19.4567 12.2374 19.5714 11.8135C20.0666 9.05187 20.2192 4.85235 19.5586 2.1934Z"
            fill="#FF0000"
        />
        <path d="M8 10.0049L13.182 7.00341L8 4.00195V10.0049Z" fill="white" />
    </svg>
);
