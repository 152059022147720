import classNames from 'classnames';


import './AppHeader.scss';
import AppHeaderAvatar from './avatar/AppHeaderAvatar';
import AppHeaderSearchWrapper from './search/AppHeaderSearch';

interface AppHeaderProps {
    sidebarStatus: boolean;
    disabledSearch?: boolean;
}

const AppHeader = ({ sidebarStatus, disabledSearch = false }: AppHeaderProps) => (
    <header className={classNames('app-header', { expand: !sidebarStatus })}>
        <div className="app-header-left">
            {!disabledSearch && <AppHeaderSearchWrapper className="ah-search"/>}
        </div>

        <div className="app-header-right">
            {/* <Notification />
            <BugReport /> */}
            <AppHeaderAvatar />
        </div>
    </header>
);

export default AppHeader;
