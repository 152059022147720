/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-deprecated */
/* eslint-disable import/namespace */
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-dropdown/style.css';

import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom/client';

import IndepthToastContainer from '@components/widgets/toast-message/ToastContainer';
import App from 'app/App';
import StoreProvider from 'providers/storeProvider';

import './baseLayout.scss';
import './main.sass';

document.addEventListener('DOMContentLoaded', function () {
    const appHeight = () => {
        const doc = document.documentElement;

        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    window.addEventListener(
        'resize',
        _.throttle(function () {
            appHeight();
        }, 200),
    );

    appHeight();
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <StoreProvider>
            <App />
            <IndepthToastContainer />
        </StoreProvider>
    </React.StrictMode>,
);
