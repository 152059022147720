import { ReducerAction } from '@shared/interfaces/store/action/reducerAction';
import { CancelTokenSource } from 'axios';
import { isEmpty } from 'lodash';
import { GENERATE_HEATMAP_FAILURE, GENERATE_HEATMAP_IS_LOADING, GENERATE_HEATMAP_SUCCESS, GET_HEATMAP_BY_PAGE_URL_SUCCESS, REMOVE_HEATMAP_FAILURE, REMOVE_HEATMAP_SCREEN_SHOT_FAILURE, REMOVE_HEATMAP_SUCCESS, SEARCH_HEATMAP_BY_PAGE_URL_SUCCESS, SELECT_HEATMAP_BY_DATE, SELECT_HEATMAP_BY_DEVICE, TAKE_NEW_SCREEN_SHOT_FAILURE, USER_HISTORY_FAILURE, USER_HISTORY_SUCCESS } from './heatmap.types';

interface ClickData {
    x: number;
    y: number;
}

interface DeviceData {
    [key: string]: IHeatmapData;
}

interface DailyData {
    [key: string]: DeviceData;
}










export interface IHeatmapData {
    pageUrl: string;
    createdAtDay: string;
    deviceType: string;
    screenShotUrl: string | null;
    coordinates: ClickData[];
    funnel: {
        '0': 0;
        '25': 0;
        '50': 0;
        '75': 0;
        '100': 0;
    };
    onPageDuration: number;
    depthRate: number;
}






export interface HeatmapState {
    pageUrl: string;
    currentPageUrl: string;
    createdAtDay: string;
    selectedHeatmap: IHeatmapData | null;
    currentHeatmaps: DeviceData | null;
    selectedDevice: string;
    devices: string[];
    heatmapList: DailyData;
    screenShotsDates: string[];
    searchResult: string[];
    userHistory: string[];
    isLoading: boolean;
    error: null | Error;
    requestSource: CancelTokenSource;
}


const selectHeatmap = (data: DailyData, createdAtDay?: string, deviceType?: string) => {
    const [defaultCreatedAtDay] = Object.keys(data);
    const targetCreatedAtDay    = createdAtDay || defaultCreatedAtDay;
    const currentHeatmaps       = data[targetCreatedAtDay];

    const devices    = Object.keys(currentHeatmaps);

    const [defaultDevice] = devices;
    const targetDevice    = deviceType && devices.includes(deviceType) ? deviceType : defaultDevice;

    const selectedHeatmap  = currentHeatmaps[targetDevice];
    const screenShotsDates = Object.keys(data);

    return {
        createdAtDay: targetCreatedAtDay,
        selectedHeatmap,
        currentHeatmaps,
        selectedDevice: targetDevice,
        devices,
        screenShotsDates,
    };
};



export const initialState: HeatmapState = {
    pageUrl: '',
    selectedHeatmap: null,
    currentHeatmaps: null,
    selectedDevice: '',
    devices: [],
    heatmapList: null,
    screenShotsDates: [],
    searchResult: [],
    isLoading: false,
    error: null,
    requestSource: null,
    createdAtDay: ''
};






const heatmapReducer = function (state: HeatmapState = initialState, action: ReducerAction): HeatmapState {

    switch (action.type) {

        case GENERATE_HEATMAP_IS_LOADING:{

            return {
                ...state,
                isLoading: true,
            };
        }


        case GENERATE_HEATMAP_SUCCESS: {
            const { data } = action.payload;

            return {
                ...state,
                isLoading: false,
                heatmapList: state.heatmapList ? { ...state.heatmapList, [data.createdAtDay]: { ...state.heatmapList[data.createdAtDay], [data.deviceType]: data } } : data as DailyData,
                selectedDevice: data.deviceType,
                currentHeatmaps: { ...state.currentHeatmaps, [data.deviceType]: data },
                devices: Object.keys(state.heatmapList[data.createdAtDay]),
                createdAtDay: data.createdAtDay,
                requestSource: action.payload.requestSource
            };
        }


        case SELECT_HEATMAP_BY_DATE: {
            const {     createdAtDay,
                selectedHeatmap,
                currentHeatmaps,
                selectedDevice,
                devices,
                screenShotsDates } = selectHeatmap(state.heatmapList, action.payload.createdAtDay);

            return { ...state,
                createdAtDay,
                selectedHeatmap,
                currentHeatmaps,
                selectedDevice,
                devices,
                screenShotsDates  };

        }

        case SELECT_HEATMAP_BY_DEVICE: {

            const {     createdAtDay,
                selectedHeatmap,
                currentHeatmaps,
                selectedDevice,
                devices,
                screenShotsDates } = selectHeatmap(state.heatmapList, state.createdAtDay, action.payload.deviceType);

            return { ...state,
                createdAtDay,
                selectedHeatmap,
                currentHeatmaps,
                selectedDevice,
                devices,
                screenShotsDates  };

        }



        case GET_HEATMAP_BY_PAGE_URL_SUCCESS: {
            const { data, pageUrl = '' } = action.payload;

            if (isEmpty(data)){
                return initialState;
            }

            const { screenShotsDates, createdAtDay, selectedHeatmap, devices, currentHeatmaps, selectedDevice } = selectHeatmap(data);

            return {
                ...state,
                createdAtDay,
                currentHeatmaps,
                selectedDevice,
                devices,
                screenShotsDates,
                heatmapList: data,
                selectedHeatmap,
                isLoading: false,
                requestSource: action.payload.requestSource,
                pageUrl
            };
        }



        case GENERATE_HEATMAP_FAILURE: {

            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        }


        case SEARCH_HEATMAP_BY_PAGE_URL_SUCCESS: {
            return {
                ...state,
                searchResult: action.payload.data,
                isLoading: false,
                requestSource: action.payload.requestSource,
            };
        }

        case USER_HISTORY_SUCCESS: {
            return {
                ...state,
                userHistory: action.payload.data,
                pageUrl: action.payload.pageUrl,
                isLoading: false,
                requestSource: action.payload.requestSource,
            };
        }

        case USER_HISTORY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        case REMOVE_HEATMAP_SCREEN_SHOT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        case TAKE_NEW_SCREEN_SHOT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        case REMOVE_HEATMAP_SUCCESS: {
            return initialState;
        }

        case REMOVE_HEATMAP_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export default heatmapReducer;
