import { useState } from 'react';

import { InputWithLabel, PrimaryButton } from '@components/common';

import './profileBilling.scss';

const initialErrorState = {
    companyName: true,
    po: false,
    billingEmail: false,
    country: false,
    address1: false,
    city: false,
    postalCode: false,
    taxType: false,
    taxId: false,
};

const initialState = {
    email: '',
    companyName: '',
    po: null,
    billingEmail: '',
    country: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    taxType: null,
    taxId: '',
};

const ProfileBilling = () => {
    const [error, setError]      = useState(initialErrorState);
    const [state, setState]      = useState(initialState);
    const [isLoading, setLoader] = useState(false);

    const onSubmit = () => null;

    const noFieldChanged = false;

    return (
        <div className='profile-billing'>
            <div>
                <h2>Billing</h2>
                <p>Changes to these preferences will apply to future invoices anly.</p>
            </div>

            <form onSubmit={onSubmit}>
                <InputWithLabel
                    title='Email address'
                    type="email"
                    value={state.email}
                    disabled
                    placeholder='Enter email address'
                />
                <InputWithLabel
                    showError={false}
                    error={error.companyName ? 'yes' : ''}
                    title='Company name'
                    type="text"
                    value={state.companyName}
                    placeholder='Enter company name'
                />
                <InputWithLabel
                    showError={false}
                    error={error.po ? 'yes' : ''}
                    title='Purchase order (PO) number'
                    type="text"
                    value={state.po}
                    placeholder='Enter po'
                />
                <InputWithLabel
                    showError={false}
                    error={error.billingEmail ? 'yes' : ''}
                    title='Billing email'
                    type="email"
                    value={state.billingEmail}
                    placeholder='You can include multiple items in email billing'
                />
                <InputWithLabel
                    showError={false}
                    error={error.billingEmail ? 'yes' : ''}
                    title='Billing email'
                    type="email"
                    value={state.billingEmail}
                    placeholder='You can include multiple items in email billing'
                />

                <div className='pb-form-head'>
                    <h3>Primary business address</h3>
                    <p>This is the physical address of the company purchasing Indepth Analytics services and is used to calculate any applicable sales tax.</p>
                </div>

                <InputWithLabel
                    showError={false}
                    error={error.country ? 'yes' : ''}
                    title='Country'
                    type="text"
                    value={state.country}
                    placeholder='Enter country'
                />
                <div className='pb-address'>
                    <InputWithLabel
                        showError={false}
                        error={error.address1 ? 'yes' : ''}
                        title='Full address'
                        type="text"
                        value={state.address1}
                        placeholder='Enter address 1'
                    />
                    <InputWithLabel
                        noLabel
                        type="text"
                        value={state.address2}
                        placeholder='Enter address 2'
                    />
                </div>
                <div className='pb-group'>
                    <InputWithLabel
                        showError={false}
                        error={error.city ? 'yes' : ''}
                        title='City'
                        type="text"
                        value={state.city}
                        placeholder='Enter city'
                    />
                    <InputWithLabel
                        showError={false}
                        error={error.postalCode ? 'yes' : ''}
                        title='Postal code'
                        type="text"
                        value={state.postalCode}
                        placeholder='Enter postal code'
                    />
                </div>

                <div className='pb-form-head'>
                    <h3>Business Tax ID</h3>
                </div>
                <div className="pb-group">
                    <InputWithLabel
                        showError={false}
                        error={error.taxType ? 'yes' : ''}
                        title='Type'
                        type="text"
                        value={state.taxType}
                        placeholder='Enter type'
                    />
                    <InputWithLabel
                        showError={false}
                        error={error.taxId ? 'yes' : ''}
                        title='ID'
                        type="text"
                        value={state.taxId}
                        placeholder='Enter your business ID'
                    />
                </div>

                <PrimaryButton disabled={isLoading || noFieldChanged} isLoading={isLoading} type="submit">
                    Save changes
                </PrimaryButton>
            </form>
        </div>
    );
};

export default ProfileBilling;
