import html2canvas from 'html2canvas';

const exportAsImage = async (el, imageFileName,cb = null) => {
    const canvas = await html2canvas(el,{
        useCORS: true,
    });
    const image  = canvas.toDataURL('image/jpeg', 1.0);

    downloadImage(image, imageFileName,cb);
};

const downloadImage = (blob, fileName,cb) => {
    const fakeLink    = window.document.createElement('a');

    fakeLink.style    = 'display:none;';
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();

    if (cb){
        cb(true);
    }
};

export default exportAsImage;
