import { useGSAP } from '@gsap/react';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { NavLink } from 'react-router-dom';

import { KeyboardArrowDownSmall } from '@components/svg/arrows/KeyboardArrowDownSmall';
import { gsap } from '@services/gsap';

import { AppNavItemDropdown, type AppNavItemDropdownItemProps } from '../';
import './AppNavItem.scss';

export interface AppNavItemProps {
    isCollapsed: boolean;
    name: string;
    icon: JSX.Element;
    isDisabled?: boolean;

    route?: string;
    onClick?: () => void;
    isItemActive?: boolean;

    dropdownItems?: AppNavItemDropdownItemProps[];
    isDropdownActive?: boolean;
    toggleDropdown?: () => void;
}

export interface AppNavItemActions {
    toggleDropdown: () => void;
}

const AppNavItem = ({ dropdownItems, toggleDropdown, isDropdownActive, ...rest }: AppNavItemProps) => {
    const [portalContainer, setPortalContainer] = useState<Element>(document.getElementById('app-nav-dropdown-portal-container'));

    useEffect(() => setPortalContainer(document.getElementById('app-nav-dropdown-portal-container')), []);

    const wrapper           = useRef<HTMLDivElement>(null);
    const { contextSafe }   = useGSAP({ scope: wrapper });
    const collapsedItemName = useRef<HTMLDivElement>(null);
    const hasDropdown       = !!dropdownItems?.length;



    const navItemId = `app-nav-item--${rest.name.toLowerCase().replace(' ', '-')}`;

    return (
        <div
            ref={wrapper}
            id={navItemId}
            className={classNames('app-nav-item-wrapper', { collapsed: rest.isCollapsed })}
        >
            <AppNavItemPreview
                onMouseEnter={contextSafe(() => {
                    if (rest.isCollapsed) {
                        gsap.set(collapsedItemName.current, { display: 'initial' });
                        gsap.fromTo(collapsedItemName.current, {
                            left: '4rem',
                            opacity: 0
                        }, {
                            left: '4.9rem',
                            opacity: 1,
                            duration: .3
                        });
                    }
                })}
                onMouseLeave={contextSafe(() => {
                    if (rest.isCollapsed) {
                        gsap.to(collapsedItemName.current, {
                            left: '4.9rem',
                            opacity: 0,
                            duration: .3,
                            onComplete: () => {
                                gsap.set(collapsedItemName.current, { display: 'none' });
                            }
                        });
                    }
                })}
                isDropdownActive={isDropdownActive}
                hasDropdown={hasDropdown}
                toggle={toggleDropdown}
                {...rest}
            />

            {hasDropdown && isDropdownActive && (
                <AppNavItemDropdown
                    items={dropdownItems}
                    navItemId={navItemId}
                    itemWrapperRef={wrapper}
                    isDropdownActive={isDropdownActive}
                    isCollapsed={rest.isCollapsed}
                    toggleDropdown={toggleDropdown}

                />
            )}
            {rest.isCollapsed && createPortal(
                <div
                    ref={collapsedItemName}
                    className="app-nav-item-name"
                    style={{ top: ((document.getElementById(navItemId)?.getBoundingClientRect()?.top ?? 0) + 2.5) + 'px' }}
                >
                    <p>{rest.name}</p>
                </div>,
                portalContainer
            )}
        </div>
    );
};

type AppNavItemPreviewProps = Omit<AppNavItemProps, 'dropdownItems'> & {
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    hasDropdown: boolean;
    isDropdownActive: boolean;
    toggle: () => void;
    isItemActive?: boolean;
    isDisabled?: boolean;
};

const AppNavItemPreview = ({ isDisabled, isItemActive, onMouseEnter, onMouseLeave, isDropdownActive, isCollapsed, name, route, icon, onClick, hasDropdown, toggle }: AppNavItemPreviewProps) => (
    onClick ? (
        <div
            className={classNames('app-nav-item', { collapsed: isCollapsed, isDropdownActive, active: isItemActive, isDisabled })}
            onClick={hasDropdown ? toggle : onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            aria-disabled={isDisabled}
        >
            {icon}
            <p>{name}</p>

            {hasDropdown && <KeyboardArrowDownSmall className='app-nav-item-toggle'/>}
        </div>
    ) : (
        <NavLink
            // activeClassName="active"
            className={({ isActive }) => classNames('app-nav-item', { collapsed: isCollapsed, isDropdownActive, active: isActive || isItemActive, isDisabled })}
            to={route}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            aria-disabled={isDisabled}
        >
            {icon}
            <p>{name}</p>

            {hasDropdown && <KeyboardArrowDownSmall className='app-nav-item-toggle'/>}
        </NavLink>
    )
);

export default AppNavItem;
