export const FacebookInMenu = () => (
    <svg width={17} height={17} viewBox="0 0 17 17" fill="none">
        <path
            d="M17 8.5C17 3.80561 13.1944 0 8.5 0C3.80561 0 0 3.80561 0 8.5C0 12.7426 3.10834 16.2591 7.17188 16.8967V10.957H5.01367V8.5H7.17188V6.62734C7.17188 4.49703 8.4409 3.32031 10.3825 3.32031C11.3124 3.32031 12.2852 3.48633 12.2852 3.48633V5.57812H11.2134C10.1574 5.57812 9.82812 6.23336 9.82812 6.90559V8.5H12.1855L11.8087 10.957H9.82812V16.8967C13.8917 16.2591 17 12.7426 17 8.5Z"
            fill="#1877F2"
        />
        <path
            d="M11.8106 10.957L12.1875 8.5H9.83008V6.90559C9.83008 6.23329 10.1594 5.57812 11.2153 5.57812H12.2871V3.48633C12.2871 3.48633 11.3144 3.32031 10.3844 3.32031C8.44285 3.32031 7.17383 4.49703 7.17383 6.62734V8.5H5.01562V10.957H7.17383V16.8967C7.61318 16.9656 8.05724 17.0001 8.50195 17C8.94667 17.0001 9.39072 16.9656 9.83008 16.8967V10.957H11.8106Z"
            fill="white"
        />
    </svg>
);
