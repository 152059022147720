export const IndepthClients = {
    dev: 'dev',
    inkyfada: 'inkyfada',

    _twala: 'twala',
    _raseef: 'raseef',
    _jummar: 'jummar',
    _cmcgaza: 'cmcgaza',
    _7amleh: '7amleh',
    _megaphone: 'megaphone',
    _alhudood: 'alhudood',
    // _almanassa: 'almanassa',
    _madamasr: 'madamasr',
    // _betaraseef: 'betaraseef',
    _reefyemen: 'reefyemen',
    // _7iber: '7iber',
    _nawa: 'nawa',
    _laha: 'laha',
    _rachma: 'rachma',
    _nefzawa: 'nefzawa',
};
