import { ComponentProps, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import TabsView, { TabsViewItemType } from '@components/common/navs/tabs-view/TabsView';
import { House } from '@components/svg/common';
import ProfileSettings from '@features/profile/profile-settings/ProfileSettings';
import SocialMediaSettings from '@features/social-media/settings/SocialMediaSettings';
import useSidebar from '@hooks/sidebar/useSidebar';
import PageWrapper from '@pages/base/PageWrapper';

import { ImpactScoreSettings } from '@features/impact-score';
import { ProfileBilling, ProfileBillingHistory, ProfilePlan } from '@features/profile';
import { useAppSelector } from '@hooks/index';
import { selectedFromUser } from '@store/authentication/authentication.reducer';
import './Settings.scss';

export enum SettingsTabsIDs {
    profile = 'profile',
    socials = 'socials',
    impactScore = 'impact-score',
    plan = 'plan',
    billing = 'billing',
    billingHistory = 'billing-history',
}

type SettingViews = { [id in SettingsTabsIDs]: (Props?: ComponentProps<any>) => JSX.Element };

export default function Settings() {
    const [searchParams, setSearchParams] = useSearchParams({ tab: SettingsTabsIDs.profile });
    const { onlySocialMedia } = useAppSelector(selectedFromUser.settings);

    const navItems: TabsViewItemType[]                   = [
        {
            title: 'Profile',
            id: SettingsTabsIDs.profile,
        },
        {
            title: 'Social media',
            id: SettingsTabsIDs.socials,
        },
        (!onlySocialMedia ? {
            title: 'Impact score',
            id: SettingsTabsIDs.impactScore,
        } : null),
        {
            title: 'Plan',
            id: SettingsTabsIDs.plan,
        },
        {
            title: 'Billing',
            id: SettingsTabsIDs.billing,
        },
        {
            title: 'Invoices',
            id: SettingsTabsIDs.billingHistory,
        },
    ].filter(Boolean);

    const settingViews: SettingViews = {
        [SettingsTabsIDs.profile]: ProfileSettings,
        [SettingsTabsIDs.socials]: SocialMediaSettings,
        [SettingsTabsIDs.impactScore]: ImpactScoreSettings,
        [SettingsTabsIDs.plan]: ProfilePlan,
        [SettingsTabsIDs.billing]: ProfileBilling,
        [SettingsTabsIDs.billingHistory]: ProfileBillingHistory,
    };
    
    const activeTab                       = (()=>{
        const tab = searchParams.get('tab') as SettingsTabsIDs;

        if (!tab  || !Object.values(SettingsTabsIDs).includes(tab)) {
            setSearchParams({ tab: SettingsTabsIDs.profile });

            return SettingsTabsIDs.profile;
        }

        return tab;
    })();

    const sidebar   = useSidebar();


    useEffect(() => {
        if(sidebar.state.sideNavStatus === true) {
            sidebar.toggleStatus(false, 'desktop');
        }

        // return () => {
        //     sidebar.resetSideNavStatus();
        // };
    }, []);

    return (
        <PageWrapper
            wrapperId="settings-wrapper"
            pageTitle={'settings'}
            shouldHideFilters
        >
            <TabsView
                className="settings-menu"
                navItems={navItems}
                navViews={settingViews}
                headerIcon={<House />}
                headerText='General settings'
                initialActiveItemId={activeTab}
                onActiveItemChange={(id) => {
                    setSearchParams({ tab: id });
                }}
            />
        </PageWrapper>
    );
}
