import download from '@assets/icons/Download.svg';
import exportAsImage from '@services/exportAsImage';
import axios from 'axios';
import classNames from 'classnames';
import ExcelJS from 'exceljs';
import React, { useCallback } from 'react';

const getFileExtension = (url) => url.split('.').pop()
    .toLowerCase();

interface WidgetDownloaderProps {
    header: string[];
    containerRef: React.RefObject<HTMLDivElement>;
    data?: any[];
    fileName?: string;
    page?: string;
    from?: string;
    to?: string;
    className?: string;
}

const WidgetDownloader = ({ className, header, containerRef, data, fileName = 'metrics',page = null,from = null,to = null }: WidgetDownloaderProps) => {
    const downloadXlsx = async () => {
        const workbook  = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        let addOn       = 0;


        if (page && from && to) {
            worksheet.addRow([`${page}`]);
            worksheet.addRow(['Date range',` ${from}/${to}`]);
            worksheet.addRow([]);

            addOn = 3;

        }

        const headerRow = worksheet.addRow(header);

        if (page && from && to) {
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ff6a83' },
                };
                cell.font = { bold: true };
            });
        }


        const addImageToWorksheet = async (url, col, row) => {
            const extension = getFileExtension(url);

            let imageBuffer = null;

            try {
                const response = await axios.get(`${url}`, { responseType: 'arraybuffer' });

                imageBuffer    = response.data;

                if (imageBuffer) {
                    const imageId = workbook.addImage({
                        buffer: imageBuffer,
                        extension: extension === 'jpg' ? 'jpeg' : extension,
                    });

                    worksheet.addImage(imageId, {
                        tl: { col, row },
                        ext: { width: 32, height: 32 },
                        editAs: 'absolute',
                    });
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };
        const imagesArrays        = [];

        for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
            const rowData  = data[rowIndex];
            const rowArray = [];

            for (let colIndex = 0; colIndex < header.length; colIndex++) {
                const key       = header[colIndex];
                const cellValue = rowData[key];

                if (typeof cellValue === 'string' && cellValue.trim().startsWith('http')) {
                    const extension = getFileExtension(cellValue.trim().replaceAll('\'',''));

                    if (['png', 'jpg', 'jpeg'].includes(extension)) {
                        imagesArrays.push({
                            image: cellValue.trim(),
                            col: colIndex,
                            row: rowIndex + 1 + addOn,
                        });
                        rowArray.push(null);
                    } else {
                        rowArray.push(cellValue);
                    }
                } else {
                    rowArray.push(cellValue);
                }
            }

            worksheet.addRow(rowArray);
        }

        for (const image of imagesArrays){
            await addImageToWorksheet(image.image.trim().replaceAll('\'',''), image.col, image.row);
        }
        worksheet.columns.forEach(col=>{
            col.width = 15;
        });

        worksheet.properties.defaultRowHeight = 25;

        const buffer = await workbook.xlsx.writeBuffer();
        const blob   = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link   = document.createElement('a');

        link.href     = URL.createObjectURL(blob);
        link.download = `${fileName}.xlsx`;
        link.click();
    };


    const downloadJpeg = useCallback(() => {
        if (containerRef.current === null) {
            return;
        }

        exportAsImage(containerRef.current, fileName);
    }, [containerRef]);

    return (
        <div className={classNames('download-wrapper', { [className]: !!className })}>
            <div className='img-wrapper'>
                <img src={download} alt="download-chart" />
            </div>
            <div className='download-drop-down'>
                <div onClick={downloadXlsx} id="downloadXlsx">Download xlsx</div>
                <div onClick={downloadJpeg} id="downloadJpeg">Download Jpeg</div>
            </div>
        </div>
    );
};

export default WidgetDownloader;
