/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from 'react';

import { KeyboardArrowRightMedium } from 'components/svg/arrows/KeyboardArrowRightMedium';

import classNames from 'classnames';

interface Props {
    nbPages: number;
    /** page index to manually select */
    updateCurrentPage?: number;
    onCurrentPageChange?: (value: number) => void;
    resetCurrentPage?: boolean;
}

// first, ..., prev, current, next, ..., last
const MINIMAL_PAGE_ITEM_COUNT = 7;

/**
 * Generate numeric page items around current page.
 *   - Always include first and last page
 *   - Add ellipsis if needed
 */
function generatePageItems(total: number, current: number, width: number) {
    if (width < MINIMAL_PAGE_ITEM_COUNT) {
        throw new Error(`Must allow at least ${MINIMAL_PAGE_ITEM_COUNT} page items`);
    }

    if (width % 2 === 0) {
        throw new Error('Must allow odd number of page items');
    }

    if (total < width) {
        return [...new Array(total).keys()];
    }

    const left                       = Math.max(0, Math.min(total - width, current - Math.floor(width / 2)));
    const items: (string | number)[] = new Array(width);

    for (let i = 0; i < width; i += 1) {
        items[i] = i + left;
    }

    // replace non-ending items with placeholders
    if ((items[0] as number) > 0) {
        items[0] = 1;
        items[1] = 'prev-more';
    }

    if ((items[items.length - 1] as number) < total - 1) {
        items[items.length - 1] = total - 1;
        items[items.length - 2] = 'next-more';
    }

    return items;
}

const TablePagination = ({
    nbPages,
    onCurrentPageChange,
    resetCurrentPage,
}: Props) => {
    const [pages, setPages] = useState(
        [...Array(nbPages).keys()]?.map((number, index) => ({
            page: number + 1,
            current: index === 0,
        })),
    );

    useEffect(() => {
        setPages(
            [...Array(nbPages).keys()]?.map((number, index) => ({
                page: number + 1,
                current: index === 0,
            })),
        );
    }, [nbPages, resetCurrentPage]);

    function handlePaginationClick(index) {
        return new Promise<number>((resolve) => {
            let selectedPage: number = null;

            setPages((prev) => {
                const result = prev.map((item, idx) => {
                    if (idx === index) {
                        selectedPage = item.page;
                        item.current = true;
                    } else {
                        item.current = false;
                    }

                    return item;
                });

                resolve(selectedPage);

                return result;
            });
        }).then((selectedPage) => {
            if (selectedPage != null) {
                onCurrentPageChange?.(selectedPage);
            }
        });
    }

    const disabled = useMemo(() => {
        const selectedPage = currentPage(pages);
        const tmp          = { next: false, prev: false };

        if (selectedPage === 1) {
            tmp.prev = true;
        } else {
            tmp.prev = false;
        }

        if (selectedPage === pages.length) {
            tmp.next = true;
        } else {
            tmp.next = false;
        }

        return tmp;
    }, [pages]);

    const pageItems = generatePageItems(
        pages.length,
        currentPage(pages),
        7
    );
    const lastPage  = pageItems.at(-1);

    return (
        <div className="cbt-table-pag">
            <div
                className={'pag-arrow' + (disabled.prev ? ' pag-disabled' : '')}
                onClick={() =>
                    prevPage(
                        setPages,
                        disabled,
                        pages,
                        nbPages,
                        onCurrentPageChange,
                    )
                }
            >
                <KeyboardArrowRightMedium
                    style={{ transform: 'rotate(-180deg)' }}
                />
            </div>

            {pageItems?.length ? (
                <>
                    {pageItems.map((item) => item === 0 ? null : (
                        <div
                            key={item}
                            className={classNames('pag-btn ', {
                                active: currentPage(pages) === item,
                                '--pag-more': typeof item !== 'number'
                            })}
                            onClick={() => {
                                if (typeof item === 'number') {
                                    handlePaginationClick(item-1);
                                }
                            }}
                        >
                            {typeof item === 'number' ? item : '...'}
                        </div>
                    ))}
                    <div
                        key={pageItems.length+1}
                        className={classNames('pag-btn ', {
                            active: currentPage(pages) === Number(lastPage) + 1,
                            '--pag-more': typeof lastPage !== 'number'
                        })}
                        onClick={() => {
                            if (typeof lastPage === 'number') {
                                handlePaginationClick(lastPage);
                            }
                        }}
                    >
                        {typeof lastPage === 'number' ? lastPage+1 : '...'}
                    </div>
                </>
            ) : null}

            <div
                className={'pag-arrow' + (disabled.next ? ' pag-disabled' : '')}
                onClick={() =>
                    nextPage(
                        setPages,
                        disabled,
                        pages,
                        nbPages,
                        onCurrentPageChange,
                    )
                }
            >
                <KeyboardArrowRightMedium />
            </div>
        </div>
    );
};

const currentPage = (pages) =>
    pages.find((page) => {
        if (page.current) {
            return page;
        }
    })?.page;

const nextPage = (setPages, disabled, pages, nbPages, onCurrentPageChange) => {
    if (disabled.next) {
        return;
    }

    let selectedPage = currentPage(pages);

    if (selectedPage + 1 > nbPages) {
        selectedPage = 1;
    } else {
        selectedPage += 1;
    }

    onCurrentPageChange(selectedPage);

    setPages((prev) =>
        prev.map((page) => {
            if (page.page === selectedPage) {
                page.current = true;
            } else {
                page.current = false;
            }

            return page;
        }),
    );
};

const prevPage = (setPages, disabled, pages, nbPages, onCurrentPageChange) => {
    if (disabled.prev) {
        return;
    }

    let selectedPage = currentPage(pages);

    if (selectedPage - 1 === 0) {
        selectedPage = nbPages;
    } else {
        selectedPage -= 1;
    }

    onCurrentPageChange(selectedPage);

    setPages((prev) =>
        prev.map((page) => {
            if (page.page === selectedPage) {
                page.current = true;
            } else {
                page.current = false;
            }

            return page;
        }),
    );
};

export default TablePagination;
