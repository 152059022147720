import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DefaultPageSkeletonBuilder from '@components/skeletons/default-page-skeleton/DefaultPageSkeletonBuilder';
import PageSuspense from '@components/skeletons/page-suspense/PageSuspense';
import SocialMediaSinglesLayout from '@layouts/social-media/SocialMediaSinglesLayout';
import AudienceSkeletonBuilder from '@pages/audience/skeleton/AudienceSkeletonBuilder';
import NotFound from '@pages/not-found/NotFound';
import RealtimeSkeletonBuilder from '@pages/realtime/skeleton/RealtimeSkeletonBuilder';

import AppRoutesBoundary from './boundary/AppRoutesBoundary';
import { DefinedRoutes } from './definedRoutes';
import PublicOutlet from './validator/PublicOutlet';
import withAuth from './with-auth/withAuth';

const Overview           = lazy(() => import('pages/overview/Overview'));
const Realtime           = lazy(() => import('pages/realtime/Realtime'));
const Audience           = lazy(() => import('pages/audience/Audience'));
const Content            = lazy(() => import('pages/content/Content'));
const GenderTracker      = lazy(() => import('pages/gender-tracker/GenderTracker'));
const HeatMap            = lazy(() => import('pages/heatmaps/HeatMap'));
const Mentions           = lazy(() => import('pages/mentions/Mentions'));
const Settings           = lazy(() => import('pages/settings/Settings'));

const SocialMedia        = lazy(() => import('@pages/social-media/SocialMedia'));
const Facebook        = lazy(() => import('@pages/social-media/singles/facebook/Facebook'));
const FacebookAllPosts = lazy(() => import('@pages/social-media/singles/facebook/all-posts/FacebookAllPosts'));

const Instagram        = lazy(() => import('@pages/social-media/singles/instagram/Instagram'));
const InstagramAllPosts = lazy(() => import('@pages/social-media/singles/instagram/all-posts/InstagramAllPosts'));
const InstagramAllStories = lazy(() => import('@pages/social-media/singles/instagram/all-stories/InstagramAllStories'));

const Twitter        = lazy(() => import('@pages/social-media/singles/twitter/Twitter'));
const TwitterAllPosts = lazy(() => import('@pages/social-media/singles/twitter/all-posts/TwitterAllPosts'));

const Youtube        = lazy(() => import('@pages/social-media/singles/youtube/Youtube'));
const YoutubeAllPosts = lazy(() => import('@pages/social-media/singles/youtube/all-posts/YoutubeAllPosts'));

const Tiktok         = lazy(() => import('@pages/social-media/singles/tiktok/Tiktok'));
const TiktokAllPosts = lazy(() => import('@pages/social-media/singles/tiktok/all-posts/TiktokAllPosts'));

const AppRoutes = (routeProps) => {
    // const selectedSite     = useGetUserSelectedSite();

    return (
        <AppRoutesBoundary>
            <Suspense fallback={<PageSuspense SkeletonBuilder={DefaultPageSkeletonBuilder}/>}>
                <Routes>
                    <Route path= "" element={<PublicOutlet />}>
                        {/* overview page */}
                        <Route index path={DefinedRoutes.overview.name} element={<Overview {...routeProps} />} />

                        {/* realtime page */}
                        <Route
                            path={DefinedRoutes.realTime.name}
                            element={
                                <Suspense fallback={<PageSuspense SkeletonBuilder={RealtimeSkeletonBuilder}/>}>
                                    <Realtime {...routeProps} />
                                </Suspense>
                            }
                        />

                        {/* audience pages */}
                        <Route
                            path={DefinedRoutes.audience.name}
                            element={<Navigate to={DefinedRoutes.audience.overview.name} />}
                        />
                        <Route
                            path={`${DefinedRoutes.audience.name}/*`}
                            element={
                                <Suspense fallback={<PageSuspense SkeletonBuilder={AudienceSkeletonBuilder}/>}>
                                    <Audience {...routeProps} />
                                </Suspense>
                            }
                        />

                        {/* content pages */}
                        <Route
                            path={DefinedRoutes.pages.name}
                            element={<Navigate to={DefinedRoutes.pages.overview.name} />}
                        />
                        <Route
                            path={`${DefinedRoutes.pages.name}/*`}
                            element={
                                <Suspense fallback={<PageSuspense SkeletonBuilder={AudienceSkeletonBuilder}/>}>
                                    <Content {...routeProps} />
                                </Suspense>
                            }
                        />

                        {/* gender tracker pages */}
                        <Route
                            path={DefinedRoutes.genderTracker.name}
                            element={<Navigate to={DefinedRoutes.genderTracker.overview.name} />}
                        />

                        <Route path={DefinedRoutes.heatmap.path} element={
                            <Suspense fallback={<PageSuspense SkeletonBuilder={AudienceSkeletonBuilder}/>}>
                                    <HeatMap {...routeProps} />
                            </Suspense>
                            } />
                        <Route
                            path={`${DefinedRoutes.genderTracker.name}/*`}
                            element={
                                <Suspense fallback={<PageSuspense SkeletonBuilder={AudienceSkeletonBuilder}/>}>
                                    <GenderTracker {...routeProps} />
                                </Suspense>
                            }
                        />

                        {/* mentions */}
                        <Route path={DefinedRoutes.mentions.path} element={<Mentions {...routeProps} />} />

                        {/* social media */}
                        <Route path={DefinedRoutes.socialMedia.name}>
                            <Route index path="" element={<SocialMedia {...routeProps} />} />
                            <Route element={<SocialMediaSinglesLayout />}>
                                <Route path={DefinedRoutes.socialMedia.singles.facebook.name}>
                                    <Route index path="" element={<Facebook {...routeProps} />} />
                                    <Route
                                        path={DefinedRoutes.socialMedia.singles.facebook.posts.name}
                                        element={<FacebookAllPosts {...routeProps} />}
                                    />
                                </Route>
                                <Route path={DefinedRoutes.socialMedia.singles.instagram.name}>
                                    <Route index path="" element={<Instagram {...routeProps} />} />
                                    <Route
                                        path={DefinedRoutes.socialMedia.singles.instagram.posts.name}
                                        element={<InstagramAllPosts {...routeProps} />}
                                    />
                                    <Route
                                        path={DefinedRoutes.socialMedia.singles.instagram.stories.name}
                                        element={<InstagramAllStories {...routeProps} />}
                                    />
                                </Route>

                                <Route path={DefinedRoutes.socialMedia.singles.twitter.name}>
                                    <Route index path="" element={<Twitter {...routeProps} />} />
                                    <Route
                                        path={DefinedRoutes.socialMedia.singles.twitter.posts.name}
                                        element={<TwitterAllPosts {...routeProps} />}
                                    />
                                </Route>

                                <Route path={DefinedRoutes.socialMedia.singles.youtube.name}>
                                    <Route index path="" element={<Youtube {...routeProps} />} />
                                    <Route
                                        path={DefinedRoutes.socialMedia.singles.youtube.posts.name}
                                        element={<YoutubeAllPosts {...routeProps} />}
                                    />
                                </Route>

                                <Route path={DefinedRoutes.socialMedia.singles.tiktok.name}>
                                    <Route index path="" element={<Tiktok {...routeProps} />} />
                                    <Route
                                        path={DefinedRoutes.socialMedia.singles.tiktok.posts.name}
                                        element={<TiktokAllPosts {...routeProps} />}
                                    />
                                </Route>
                            </Route>
                        </Route>

                        {/* settings */}
                        <Route path={DefinedRoutes.settings.path} element={<Settings {...routeProps} />} />

                    </Route>

                    <Route path="*" element={<NotFound />}/>
                </Routes>
            </Suspense>
        </AppRoutesBoundary>
    );
}

export default withAuth(AppRoutes);