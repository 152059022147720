/* eslint-disable @typescript-eslint/naming-convention */
import { type SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import {
    type SocialMediaSingleActiveProfiles,
    type SocialMediaSingleEngagement,
    type SocialMediaSingleInteractionByPost,
    type SocialMediaSinglePostFormat,
    type SocialMediaSinglePostsList,
    type SocialMediaSingleReach,
    type SocialMediaSingleTopAndWeakPosts
} from '@shared/interfaces/social-media';

import { SocialMediaAPIClient } from '@services/axios/clients/socialMediaAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { SocialPostTypes } from '@shared/enum';
import { InstagramTotalInteractions, SocialMediaSingleAllPosts, SocialMediaSingleFollowersGrowth, SocialMediaSingleImpressions, SocialMediaSingleReachAndImpressions, SocialMediaSingleReachByPost, SocialMediaSingleStoriesResults, SocialMediaSingleTwitterImpressionsHistory, SocialMediaSingleYoutubeVideosShortsViews, SocialMediaTiktokViewsVsEngagement } from '@shared/interfaces/social-media/socialMediaSingle';

interface SocialMediaSingleParams {
    id: string;
    start_date: string;
    end_date: string;
    compareFrom: string;
    compareTo: string;
    client_id: string;
}

interface HighlightItem {
    total: string;
    percentage: string;
    difference: string;
}

interface PostActivityItem {
    [name: string]: {
        compareDate: string;
        date1: number;
        date2: number;
        difference: number;
        percentage: number;
    };
}

export interface PostActivityThunk {
    [date: string]: PostActivityItem | null;
}

export interface PostInteractionItem {
    [date: string]: {
        compareDate: string;
        date1: number;
        date2: number;
        difference: number;
        percentage: number;
    };
}

export interface PostInteractionThunk {
    comments: PostInteractionItem[];
    'link video clicks': PostInteractionItem[];
    reactions: PostInteractionItem[];
    shares: PostInteractionItem[];
    [name: string]: PostInteractionItem[];
}

interface SocialMediaSingleReturn {
    networkType: SocialMediaTypes;
    profileName: string;
    url: string;
    followers: number | {
        difference: number;
        followers: number;
        gain: number;
        loss: number;
    };
    content: {
        avgViewDuration: number;
        totalVideo: number;
        totalViews: number;
        totalWatchTime: number;
    };
    avgInteractionsPerDay: {
        comments: number;
        intercationsPerDay: number;
        likeDislikeRatio: string;
        shares: number;
    };
    lastVideoPerformance: {
        comments: number;
        date: string;
        engagementRate: number;
        id: number;
        image: string;
        interaction: string;
        link: string;
        message: string;
        reference: string;
        type: SocialPostTypes;
        views: number;
    };
    dislike: HighlightItem & {
        value?: number;
        pastValue?: number;
    };
    pageViews: number;
    pageViewsPerDay: number;
    overAll: number;
    storiesResults: SocialMediaSingleStoriesResults;
    totalTweets: HighlightItem & {
        value?: number;
        pastValue?: number;
    };
    likes: HighlightItem & {
        value?: number;
        pastValue?: number;
    };
    favorites: HighlightItem & {
        value?: number;
        pastValue?: number;
    };
    replies: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    comments: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    retweetAndShare: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    shares: {
        value: number;
        pastValue: number;
        percentage: number;
    };
    followerEvolution: number;
    followersEvolution: {
        value: number;
        pastValue: number;
        percentage: number;
    };

    followersEvolutionPercentage: number;

    profileFollowersGain: number;
    profileFollowersLost: number;
    posts: HighlightItem;

    postInteraction: HighlightItem & { sparklines: string[] };
    postPerDay: HighlightItem & { sparklines: string[] };
    engagementRate: HighlightItem & { sparklines: string[] };
    followerGrowth: SocialMediaSingleFollowersGrowth;

    postFormat: SocialMediaSinglePostFormat;
    postActivity: PostActivityThunk;
    interaction: PostInteractionThunk;
    interactionByPost: SocialMediaSingleInteractionByPost;
    totalInteractions: InstagramTotalInteractions;
    reachByPostType: SocialMediaSingleReachByPost;
    impressionsByPost: SocialMediaSingleReachByPost;
    topAndWeakPosts: SocialMediaSingleTopAndWeakPosts;
    postList: SocialMediaSinglePostsList;
    // engagement: SocialMediaSingleEngagement;
    reach: SocialMediaSingleReach;

    impression: SocialMediaSingleImpressions;
    impressions: SocialMediaSingleImpressions;

    ReachAndImpression: SocialMediaSingleReachAndImpressions;
    reachAndImpression: SocialMediaSingleReachAndImpressions;
    impressionsHistory: SocialMediaSingleTwitterImpressionsHistory;

    videoAndShortsView: SocialMediaSingleYoutubeVideosShortsViews;

    // for tiktok
    overallPosts: number;
    overallLikes: number;
    viewsVsEngagement: SocialMediaTiktokViewsVsEngagement;
    engagement: SocialMediaSingleEngagement | {
        difference: string;
        pastValue: number;
        percentage: number;
        value: number;
    };
    views: {
        value: number;
        pastValue: number;
        percentage: number;
    };
}

export const fetchSocialMediaSingle = createAsyncThunkWithClient<SocialMediaSingleReturn, SocialMediaSingleParams>({
    requestFn: (
        params,
        config
    ) => SocialMediaAPIClient.getSingle<SocialMediaSingleParams>(
        params,
        config
    ),
    typePrefix: 'socialMediaSingle/all'
});

interface FetchSocialMediaSingleActiveProfilesParams {
    clientId: string;
}

export const fetchSocialMediaSingleActiveProfiles = createAsyncThunkWithClient<SocialMediaSingleActiveProfiles, FetchSocialMediaSingleActiveProfilesParams>({
    requestFn: (
        params,
        config
    ) => SocialMediaAPIClient.getActiveProfiles<SocialMediaSingleActiveProfiles>(
        params.clientId,
        config
    ),
    typePrefix: 'socialMediaSingle/getActiveProfiles'
});

interface FetchSocialMediaSingleAllPostsParams {
    id: string;
    start_date: string;
    date_end: string;
    page: number;
    limit: number;
    sortby: string;
    type: string;
}

export const fetchSocialMediaSingleAllPosts = createAsyncThunkWithClient<SocialMediaSingleAllPosts, FetchSocialMediaSingleAllPostsParams>({
    requestFn: (
        params,
        config
    ) => SocialMediaAPIClient.getAllPosts<FetchSocialMediaSingleAllPostsParams>(
        params,
        config
    ),
    typePrefix: 'socialMediaSingle/allPosts'
});

interface FetchSocialMediaSinglePostParams {
    id: string;
}

export const fetchSocialMediaSinglePost = createAsyncThunkWithClient<any, FetchSocialMediaSinglePostParams>({
    requestFn: (
        params,
        config
    ) => SocialMediaAPIClient.getPost<FetchSocialMediaSinglePostParams>(
        params,
        config
    ),
    typePrefix: 'socialMediaSingle/post'
});




interface FetchSocialMediaSingleAllStoriesParams {
    id: string;
    start_date: string;
    date_end: string;
    page: number;
    limit: number;
    sortby: string;
    type: string;
}

export const fetchSocialMediaSingleAllStories = createAsyncThunkWithClient<SocialMediaSingleAllPosts, FetchSocialMediaSingleAllStoriesParams>({
    requestFn: (
        params,
        config
    ) => SocialMediaAPIClient.getAllStories<FetchSocialMediaSingleAllStoriesParams>(
        params,
        config
    ),
    typePrefix: 'socialMediaSingle/allStories'
});

interface FetchSocialMediaSingleStoryParams {
    id: string;
}

export const fetchSocialMediaSingleStory = createAsyncThunkWithClient<any, FetchSocialMediaSingleStoryParams>({
    requestFn: (
        params,
        config
    ) => SocialMediaAPIClient.getPost<FetchSocialMediaSingleStoryParams>(
        params,
        config
    ),
    typePrefix: 'socialMediaSingle/story'
});
