import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { isEmpty } from 'lodash';
import React, { ReactNode, useRef } from 'react';
import { useWindowSize } from 'usehooks-ts';

import TablePaginationWrapper from '@components/common/table/pagination/TablePaginationWrapper';
import TableSearch from '@components/common/table/search/TableSearch';
import TdItemWrapper from '@components/common/table/td-item/TdItemWrapper';
import ThItemWrapper from '@components/common/table/th-item/ThItemWrapper';
import Loader from '@components/widgets/loader/Loader';
import useSidebar from '@hooks/sidebar/useSidebar';
import { widthDetector } from '@services/width-detector/widthDetector';

import WidgetDownloader from '@components/widget-downloader/WidgetDownloader';
import { CountriesTopContentItem, CountryAllContentTableItem } from '@shared/interfaces/store/country';
import { AuthorArticleItem } from '@store/author/dto/getAuthorArticles.dto';
import './Table.sass';

export declare interface TableProps {
    nbPages?: number;
    /**
     * index of the default selected item
     */
    defaultOrder?: number;
    reversedDefault?: boolean;
    headerItems: ({ title: string; isSortable: boolean } | string)[];
    onOrderChange?: (title: string, reversed?: boolean) => void;
    bodyItems: (Record<string, any> | string | ReactNode)[][];
    emptyBodyItemsMessage?: string;
    itemsNotBold?: boolean;

    disablePagination?: boolean;
    setNbResults?: (value: number) => void;
    defaultSelectedNbResults?: number;
    onCurrentPageChange?: (value: number) => void;
    resetCurrentPage?: boolean;

    showBodyItemArrow?: boolean;
    goToLinkWhenClickedOnTdFirstChild?: boolean;

    wrapperClass?: string;

    // search methods
    enableSearch?: boolean;
    onSearchInputChange?: React.FormEventHandler<HTMLInputElement>;
    onSearchInputKeyUp?: React.FormEventHandler<HTMLInputElement>;

    isLoading?: boolean;

    theadClass?: string;
    tbodyClass?: string;
    tableWrapperId?: string;

    ref?: React.MutableRefObject<HTMLDivElement>;
    allItems?: CountriesTopContentItem[] | CountryAllContentTableItem[] | AuthorArticleItem[],
    header?: string[],
    disabledDownloader?: boolean;
    from?: string;
    to?: string;
    page?:string;
}

function Table ({
    headerItems,
    bodyItems=[],

    emptyBodyItemsMessage = 'No Data',
    showBodyItemArrow = true,
    itemsNotBold = false,

    enableSearch = false,
    onSearchInputChange,
    onSearchInputKeyUp,

    disablePagination = false,
    setNbResults,
    defaultSelectedNbResults,
    nbPages = null,
    onCurrentPageChange,
    resetCurrentPage,

    onOrderChange,
    defaultOrder = 0,
    reversedDefault,

    isLoading,

    wrapperClass = '',
    theadClass,
    tbodyClass,
    tableWrapperId = 'cbt-table-outer-wrapper',

    goToLinkWhenClickedOnTdFirstChild,
    ref,
    allItems,
    header,
    disabledDownloader,
    from = null,
    to = null,
    page = null
}: TableProps) {
    const size           = useWindowSize();
    const sidebar        = useSidebar();
    const tableContainer = useRef<HTMLDivElement>();

    useGSAP(() => {
        const currentDevice = Number(widthDetector.getCurrentDevice());

        if (currentDevice < 1024) {
            gsap.to(tableContainer.current, {
                maxWidth: (window.innerWidth - 45) + 'px',
            });
        }
    }, { dependencies: [size, sidebar], scope: tableContainer });

    return (
        <>
            {
                enableSearch ? (
                    <div className="cbt-search">
                        <TableSearch
                            onChange={onSearchInputChange}
                            onKeyUp={onSearchInputKeyUp}
                        />
                        
                    </div>
                ) : null
            }
            <div className='table-wrapper'>
                {!disabledDownloader && <WidgetDownloader className="cbt-table-downloader" from={from} to={to} page={page} data={allItems} header={header} containerRef={tableContainer} />}
                <div
                    className="cbt-table-container"
                    id={tableWrapperId}
                    ref={(el)=> {
                        tableContainer.current = el;

                        if (ref) {
                            ref.current = el;
                        }
                    }}
                >
                <table className={'cbt-table-wrapper ' + wrapperClass} rules="groups">
                    <thead className={theadClass} data-disabled={isLoading}>
                        <tr>
                            <ThItemWrapper onChange={onOrderChange} defaultOrder={defaultOrder} reversedDefault={reversedDefault} items={headerItems} />
                        </tr>
                    </thead>
                    <tbody className={tbodyClass}>
                        {
                            isLoading ? (
                                <tr className="cbt-loader-wrapper">
                                    <td className="cbt-loader-td" align="center" colSpan={headerItems.length}>
                                        <Loader />
                                    </td>
                                </tr>
                            ) : (
                                !isEmpty(bodyItems) ?  (
                                    <TdItemWrapper
                                        key={`table-td-wrapper-${Math.random() * 1000}`}
                                        goToLinkWhenClickedOnTdFirstChild={goToLinkWhenClickedOnTdFirstChild}
                                        showArrow={showBodyItemArrow}
                                        bodyItems={bodyItems}
                                        itemsNotBold={itemsNotBold}
                                        tableWrapperId={tableWrapperId}
                                    />
                                ) : (
                                    <tr className="cbt-center-tr">
                                        <td align="center" colSpan={headerItems.length}>
                                            <h3>{emptyBodyItemsMessage}</h3>
                                        </td>
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </table>
            </div>
            {
                !disablePagination && nbPages ? (
                    <TablePaginationWrapper
                        setNbResults={setNbResults}
                        defaultSelectedNbResults={defaultSelectedNbResults}

                        nbPages={nbPages}
                        onCurrentPageChange={onCurrentPageChange}
                        resetCurrentPage={resetCurrentPage}
                    />
                ) : null
            }
            </div>
            
        </>
    );
}

// function fix1024Width(sidebar, tableContainer) {
//     if (sidebar.state.sideNavStatus === false){
//         gsap.to(tableContainer.current, {
//             maxWidth: '870',
//         });
//     } else {
//         gsap.to(tableContainer.current, {
//             maxWidth: '710px',
//         });
//     }
// }

export default Table;
